import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppointmentsType from '../types/rest/AppointmentType';
import { stat } from 'fs';

interface MessagesState {
    status: "success"|"error"|"warning"|undefined
    message: String|undefined;
}

const initialState: MessagesState = {
    status: undefined,
    message: undefined
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<String|undefined>) => {
        state.status = "error"
        state.message = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<String|undefined>) => {
        state.message = action.payload;
        state.status = "success";
    },
    setWarningMessage: (state, action: PayloadAction<String|undefined>) => {
        state.message = action.payload;
        state.status = "warning";
    },
    reset: (state) => {
        state.status = undefined;
        state.message = undefined;
    },
  },
});

export const getMessageStatus = (state: { messages: {status: "success"|"error"|"warning"|undefined} }) => {
    return state.messages.status;
}

export const getMessage = (state: { messages: {message: String|undefined} }) => {
    return state.messages.message;
}

export const { reset, setErrorMessage, setSuccessMessage, setWarningMessage } = messagesSlice.actions;

export default messagesSlice.reducer;