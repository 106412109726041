import { useEffect, useRef, useState } from "react";
import { AddFormContainer, BoxBodyContainer, BoxContainer, ChangeStatusBtnStyle, ChoiceBoxStyle, InputCalendarStyle, InputContainerStyle, InputTextStyle, InputTimeStyle, LabelShiftStyle, LocalizationProviderStyle, RowDateStyle, RowDeleteButtonStyle, RowStyle, RowTimeStyle, ShiftContainer, ShiftTimeStyle, ShiftTitleStyle, SubmitBtnStyle, TDShiftsStyle, TRStyle, TableContainer } from "./Style";
import { useIntl } from "react-intl";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import CancelIcon from "../assets/icons/XIcon.svg";
import { createSpecialDay, deleteSpecialDays, getShifts, getSpecialDays } from "../../services/BackendServices";
import ShiftType from "../../types/rest/ShiftType";
import ShiftsList from "../shiftsList/ShiftsList";
import { SpecialDayType } from "../../types/rest/SpecialDayType";


const SpecialDayController = () => {
    const { formatMessage } = useIntl();

    const [value, setValue] = useState<Dayjs | null>(dayjs(new Date()));
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const [addingSpecialDay, setAddingSpecialDay] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState(false);

    const [isDragging, setIsDragging] = useState(false);
    const [startDragY, setStartDragY] = useState(0);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const [shifts, setShifts] = useState<ShiftType[]>([]);
    const [specialDays, setSpecialDays] = useState<SpecialDayType[]>([]);

    const [selectedShifts, setSelectedShifts] = useState<ShiftType[]>([]);

    const onMouseDown = (event: React.MouseEvent) => {
        setIsDragging(true);
        setStartDragY(event.clientY);
    }

    const onMouseMove = (event: React.MouseEvent) => {
        if (!isDragging || !containerRef.current) return;
        containerRef.current.scrollTop += startDragY - event.clientY;
        setStartDragY(event.clientY);
    }

    const onMouseUp = () => {
        setIsDragging(false);
    }

    useEffect(() => {
        const preventDefault = (event: Event) => event.preventDefault();
        if (isDragging) {
            window.addEventListener('selectstart', preventDefault);
        }
        return () => {
            window.removeEventListener('selectstart', preventDefault);
        }
    }, [isDragging]);

    useEffect(() => {
        fetchData();
    }, [inProgress, selectedDate])

    const fetchData = async () => {
        const specialDays = await getSpecialDays();
        if (Array.isArray(specialDays)) {
            setSpecialDays(specialDays);
        }
        else {
            setSpecialDays([]);
        }

        const shifts = await getShifts();
        if (Array.isArray(shifts)) {
            if(Array.isArray(specialDays)) {
                const selectedSpecialDay = specialDays.find(specialDay=> {
                    return specialDay.date===formatDate(selectedDate);
                });
                if(selectedSpecialDay) {
                    const selectedShifts = selectedSpecialDay.shifts;
                    setSelectedShifts(selectedShifts);
                    setShifts(shifts.filter(shift=>{
                        return !selectedShifts.flatMap(x=>x.id).includes(shift.id);
                    }));

                }
                else {
                    setShifts(shifts);
                    setSelectedShifts([]);
                }
            }
            else {
                setShifts(shifts);
                setSelectedShifts([]);
            }
        }
        else {
            setShifts([]);
            setSelectedShifts([]);
        }
    }

    const switchBox = () => {
        if (inProgress) return;
        setAddingSpecialDay(!addingSpecialDay);
    }

    function formatDate(date: Date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JS months are 0-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const handleDateChange = (newValue: any) => {
        setSelectedDate(newValue.$d);
        setValue(dayjs(newValue.$d))
    }

    const addNewSpecialDay = async () => {
        const selectedDateS = formatDate(selectedDate);

        const shiftIds = selectedShifts.flatMap(sh => sh.id);

        const response = await createSpecialDay(selectedDateS, shiftIds)

        if (response.statusCode === 200) {
            console.log("OK", response.message)
        }
        else {
            console.log("ERROR", response.message)
        }

        fetchData();
    }

    const removeSpecialDay = async (specialDayId:string) => {
        const response = await deleteSpecialDays(specialDayId)

        if (response.statusCode === 200) {
            console.log("OK", response.message)
        }
        else {
            console.log("ERROR", response.message)
        }

        fetchData();
    }

    const addShift = (shiftName: string) => {
        const selectedShift = shifts.find(s => s.shiftName === shiftName);

        if (selectedShift) {
            const updatedShifts = shifts.filter(s => s.shiftName !== shiftName);
            const updatedSelectedShifts = [...selectedShifts, selectedShift];

            setShifts(updatedShifts);
            setSelectedShifts(updatedSelectedShifts);
        }
    }

    const removeShift = (shiftName: string) => {
        const selectedShift = selectedShifts.find(s => s.shiftName === shiftName);

        if (selectedShift) {
            const updatedSelectedShifts = selectedShifts.filter(s => s.shiftName !== shiftName);
            const updatedShifts = [...shifts, selectedShift];

            setShifts(updatedShifts);
            setSelectedShifts(updatedSelectedShifts);
        }
    }

    const addingSpecialDayForm = () => {
        return <AddFormContainer>
            <ChoiceBoxStyle>
                <LabelShiftStyle>Shifts:</LabelShiftStyle>
                <ShiftsList shifts={shifts.flatMap(x => x.shiftName)} onClick={addShift} />
            </ChoiceBoxStyle>
            <ChoiceBoxStyle>
                <LabelShiftStyle>Selected:</LabelShiftStyle>
                <ShiftsList shifts={selectedShifts.flatMap(x => x.shiftName)} onClick={removeShift} />
            </ChoiceBoxStyle>
            <ChoiceBoxStyle>
                <LocalizationProviderStyle dateAdapter={AdapterDayjs}>
                    <InputContainerStyle>
                        <InputCalendarStyle
                            value={value}
                            onChange={handleDateChange}
                        />
                    </InputContainerStyle>
                </LocalizationProviderStyle>
            </ChoiceBoxStyle>
            <SubmitBtnStyle onClick={addNewSpecialDay}>
                {formatMessage({ id: "add_special_day" })}
            </SubmitBtnStyle>
        </AddFormContainer>
    }

    const specialDaysListForm = () => {
        return <TableContainer
            ref={containerRef}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
        >
            {specialDays.map((specialDay) => (
                <RowStyle key={specialDay.id}>
                    <RowDateStyle>{specialDay.date}</RowDateStyle>
                    <RowTimeStyle>{specialDay.shifts.map(shift=>{
                        return <div>{shift.fromHour}-{shift.toHour}</div>
                    })}</RowTimeStyle>
                    <RowDeleteButtonStyle src={CancelIcon} onClick={()=>removeSpecialDay(specialDay.id)} />
                </RowStyle>
            ))}
        </TableContainer>
    }

    return (
        <BoxContainer boxSize={!addingSpecialDay ? "small" : "big"}>
            <ChangeStatusBtnStyle onClick={switchBox}>Switch to {formatMessage({ id: addingSpecialDay ? "special_days" : "add_special_day" })}</ChangeStatusBtnStyle>
            <BoxBodyContainer>
                {(addingSpecialDay && addingSpecialDayForm()) ||
                    specialDaysListForm()}
            </BoxBodyContainer>
        </BoxContainer>
    )
}

export default SpecialDayController;