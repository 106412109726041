import { FBStyle, FooterContainerStyle, InstagramStyle, LocationStyle, PhoneNumberStyle, SocialMediaContainerStyle, SignatureStyle, FooterDetailsStyle, FooterDetailLineStyle, IconStyle, SocialInstaGramHandler, SocialImage } from "./Footer.Style";
import FBIcon from "../../assets/icons/TwitterWhite.svg"
import InstagramIcon from "../../assets/icons/FBWhite.svg"
import PhoneIcon from "../../assets/icons/iphone.svg"
import { FormattedMessage } from "react-intl";
import MenuWhatsAppLogo from "../../../components/assets/icons/MenuWhatsAppLogo.svg"


const openMapsApp = () => {
    const mapsUrl = "https://waze.com/ul/hsvc48wp92"
    window.open(mapsUrl, "_blank")
  }

  const openInstagramApp = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const Url = "https://www.instagram.com/alfena213?igsh=cXhndXg0dHhrdTh1"
    // Open the URL in a new tab/window
    window.open(Url, "_blank")
  }

const sendViaWhatsApp = () => {
    const phoneNumber = "+972534257390";
    const message = "Hello, I'm sending this message via WhatsApp!";
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
}

  

  const callNumber = () => {
    // Construct the maps URL with the fixed location
    // const mapsUrl = `https://maps.google.com/maps?q=${fixedLocation}`;
    const Url = "tel:0534257390"
    // Open the URL in a new tab/window
    window.open(Url, "_blank")
  }

const Footer = () => {
    return (
        <>
            <FooterContainerStyle>
                <FooterDetailsStyle>
                    <FooterDetailLineStyle>

                        <LocationStyle onClick={openMapsApp}>
                            <FormattedMessage id={'Data.Location'}/>
                        </LocationStyle>
                    </FooterDetailLineStyle>
                    <FooterDetailLineStyle>
                        <IconStyle src={PhoneIcon} />
                        <PhoneNumberStyle onClick={callNumber}>
                            053-425-7390
                        </PhoneNumberStyle>
                    </FooterDetailLineStyle>
                </FooterDetailsStyle>
                <SocialMediaContainerStyle>
                <SocialInstaGramHandler onClick={openInstagramApp}/>
                    {/* <FBStyle src={FBIcon} /> */}
                    <SocialImage src={MenuWhatsAppLogo} onClick={sendViaWhatsApp}/>
                </SocialMediaContainerStyle>
                {/* <SignatureStyle>
                </SignatureStyle> */}
            </FooterContainerStyle>
        </>
    )
}

export default Footer;