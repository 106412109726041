import { Link } from "react-router-dom";
import styled from "styled-components";

export const TabBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background: #f0f0f0; // Change as per your design
  font-family: 'Jost', sans-serif;
  padding:2px 4px 2px 4px;
  bottom: 0%;
  width: calc(100% - 22px);
  align-items: center;
  margin:5px 7px;
  border-radius: 10px;
  height: 4em;
  direction: ltr;
  align-content: center;
  line-height: 1.5em;
`;

export const TabButton = styled(Link)<{ active: boolean }>`
  background: none;
  border: none;
  padding: 7px;
  color: ${props => props.active ? '#000' : '#ccc'}; // Active tabs in black, others in gray
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  text-decoration: none;
`;
