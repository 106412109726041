import En from './en.json';
import He from './he.json';
import Ar from './ar.json';

const translations = {
  En,
  He,
  Ar
};

export default translations;