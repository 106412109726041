import styled from "styled-components";
import homeBack from '../assets/images/alfena_main_image.jpg'


export const SliderContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh );
    max-height: 100%;
    background-image: url(${homeBack});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* margin-bottom: 3em; */
    
`

export const SliderHeader = styled.div`
    /* height: calc(100vh ); */
    background: linear-gradient(180deg, #222532 0%, rgba(34, 37, 50, 0.87) 39.06%, rgba(34, 37, 50, 0.42) 72.92%, rgba(34, 37, 50, 0) 100%);

`

export const SliderFooter = styled.div`
    margin-top: auto;
    height: 15%;
    background: linear-gradient(180deg, rgba(34, 37, 50, 0) 0%, #222532 100%);
`

export const SliderFooterText = styled.div`
    /* line-height: 60px; */
    text-align: center;
    font-family: 'Jost', sans-serif;
    letter-spacing: 0.24em;
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 55px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 1.92px;
`

export const StyleHeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    background: linear-gradient(180deg, #222532 0%, rgba(34, 37, 50, 0.87) 5%, rgba(34, 37, 50, 0.42) 30%, rgba(34, 37, 50, 0) 100%);
`;

export const StyleLogo = styled.img`
    width: 90%;
    max-width: 400px;
    max-height: 350px;
`;


