import styled from 'styled-components';

export const HistoryMainContainer = styled.div`
    display:flex;
    flex-direction: column;
    margin: 0 10px;
    height: 100%;
    overflow-y: auto;
    border-radius: 10px;
`;

export const UserDataContainer = styled.div`
    /* margin: 5px 0; */
`;

export const UserDataItem = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap : 5%;
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    font-size: 20px;
    padding: 10px;
    margin: 0px 0px 10px 0px;
    border-radius: 10px;
    background-color: #c5a496;
    direction: ltr;
`;

export const UserDataImage = styled.img`

`;

export const UserDataIdContainer = styled.div`
    /* Add your styles for UserDataIdContainer here */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
    width: 60%;
    width: fit-content;
    font-size: 24px;
    font-weight: 500;
    margin-right: 5px;
    align-items: center;
    margin-right: auto;
`;

export const UserDataType = styled.div`
    width: 30%;
    border-radius: 10px;    
    background-color: #8b6a61;  
    border: 1px solid black;  
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const UserDataTimeStamp = styled.div`
    /* Add your styles for UserDataTimeStamp here */
    display: flex;
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    padding: 5px 0;
    direction: ltr !important;
`;

export const UserDataOperaion = styled.div`

`;
