import styled from "styled-components";

export const WorkingTimesContainer = styled.div`
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 20%;
    font-family: 'Jost',sans-serif;
    font-weight: 600;
    `

export const TableStyle = styled.table`
    width: 100%;
    border-radius: 50px;
    
    td, th {
        padding: 15px 0;
        text-align: center;
    }

    th {
        background-color: #8b6a61;
        /* color: whitesmoke; */
        padding: 20px 0;
    }

    tr:nth-child(even) {
        background-color: #c5a486;
    }

    tr:nth-child(odd) {
        background-color: #c5a496;
    }
`;

export const TableHeaderStyle = styled.thead`

`

export const TableBodyStyle = styled.tbody`

`

export const TRStyle = styled.tr`
    max-width: calc(100% - 100px);
`

export const THDayStyle = styled.th`
    width: 100px;
`

export const THShiftStyle = styled.th`

`

export const TDDayStyle = styled.td`
    max-width: 100%;
`

export const TDShiftsStyle = styled.td`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
`

export const ShiftTimeStyle = styled.span`
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    /* bottom: 130%; */
    left: 50%; /* Position the tooltip's left edge in the center */
    transform: translateX(-50%); /* Shift the tooltip back by half its width */
    opacity: 0;
    transition: opacity 0.3s;
`;

export const ShiftContainer = styled.div`
    display: flex;
    gap: 5px;
    padding: 5px 10px;
    background-color: #8b6a61;
    border-radius: 5px;
    user-select: none;

    position: relative;
  
    &:hover ${ShiftTimeStyle} {
        visibility: visible;
        opacity: 1;
    }
`

export const ShiftTitleStyle = styled.div`

`

export const ShiftDeleteStyle = styled.div`
    cursor: pointer;
`