import styled from 'styled-components';

interface HomePageProps {
    isDisplayed: boolean;
}

export const UserAppointmentsMainContainer = styled.div<HomePageProps>`
    width: 100%;
    height: ${props => props.isDisplayed ? '100%' : '90%'};
    background-color:#292929;
    position: fixed;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    top: ${props => props.isDisplayed ? '0' : '5vh'};
    left: 0;
    transform: ${props => props.isDisplayed ? 'translateX(0)' : 'translateX(100%)'};
    overflow-y: scroll;  // Prevents vertical overflow: ;
    overflow-x: hidden;
    transition-delay: ${props => props.isDisplayed ? '0.5s, 0.5s, 0s' : '0s, 0s, 0.5s'};
    transition-duration: 0.5s, 0.5s, 0.5s;
    transition-property: height, top, transform;
    transition-timing-function: ease-out, ease-out, ease-out;
`;

export const UserAppointmentsHeader = styled.div`
    /* position: relative; */
    width:calc(100% - 2em) ;
    top: 0;
    height: 4em;
`;

export const UserAppointmentsMainContent = styled.div`
/* margin-top: 1%; */
    width:100%;
    height: calc(100% - 4em);
    border-radius: 57px 57px 0px 0px;
    border: 1px solid #49506F;
    background: linear-gradient(180deg, #c5a496 0%, #c5c4b6 100%);
    display: flex;
    flex-direction: column;
`;

export const UserAppointmentsFooter = styled.div`
    position: relative;
    width: 100%;

`;

export const ContentUserData = styled.div`
    width: 100%;
    /* padding: 0.5em; */

`;

export const UserDataHeader = styled.div`
    color: #222532;
    font-family: 'Jost',sans-serif;
    font-size: 24px;
    font-weight: 400;
    padding: 1em 1em 0.5em 1em;
`;

export const CurrentAppointments = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin:1rem;   
    border:2px solid #222532;
    border-radius:20px; 
    gap:1px;
    
`;

export const AppointmentCard = styled.div`
    /* border: 1px solid #222532; */
    direction: ltr;
    padding:0.5em;
    border-bottom:2px solid #222532; ;
    &:last-child{
        border-bottom:0px solid #222532; ;
    }
`;

export const TimeAndButtonLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
`;


export const AppointmentHeader = styled.div`
    /* direction: ltr; */
    color: #222532;
    font-family: 'Jost',sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 1em;
`;

export const AppointmentTime = styled.div`
    direction: ltr;
    color: #222532;
    font-family: 'Jost',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: row;
    gap: 5px;
`
export const AppointmentTimeFrom = styled.div`
    direction: ltr;
    color: #222532;
    font-family: 'Jost',sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.5rem 0px 0px 1rem;
`
export const AppointmentTimeTo = styled.div`
    direction: ltr;
    color: #222532;
    font-family: 'Jost',sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const ButtonsLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
    position: relative;
    right: 0;
`;

export const AppointmentCardButton = styled.img`

`;

export const MenuContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const BlurredBackGround = styled.div`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    position: absolute;
    z-index: 5;
    
`;

export const ConfirmButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 2px solid #222532;
  background: #222532;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* height: 100%; */
  /* width: 40%; */
  color: #fff;
  padding: 1rem;
  

`;

export const CancelButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  padding: 1rem;
  border-radius: 40px;
  border: 2px solid #222532;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  
`;

export const PopUpDeleteMessage = styled.div`
    font-family: 'Jost', sans-serif;
    width: 80%;
    margin-top: 50%;
    /* height: 50%; */
    margin-left: auto;
    margin-right: auto;
    padding :1rem;
    /* position: absolute; */
    display: flex;
    z-index: 5;
    flex-direction: column;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #c5a496 0%, #8b6a61 100%) ;
    border: 1px solid #000000;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    gap: 28px;
`;

export const PopUpHeaderDeleteConfirmMsg = styled.div`
    font-size:larger;
    
`;

export const DeleteButtonsContainer = styled.div`
    display: flex;
    flex-direction:row;
    gap:20px;
`;



