import { useEffect, useState } from "react"
import ISignInPopupProps from "./ISignInPopupProps"
import {
  BackPopupWindowStyle,
  BlurredBackGroundDiv,
  ErrorMessageStyle,
  ForgetPasswordStyle,
  FormContainerStyle,
  InnerContainerStyle,
  InputTextStyle,
  LoginButtonStyle,
  ParentContainer,
  PopupWindowStyle,
  PopupWindowSubTitleStyle,
  PopupWindowTitleStyle,
  RegisterButtonStyle
} from "./Styles"
import { confirmUser, login, register } from "../../services/BackendServices"
import LoginSuccessfullyType from "../../types/rest/LoginSuccessfullyType"
import LoginFailedType from "../../types/rest/LoginFailedType"
import RegisterSuccessfullyType from "../../types/rest/RegisterSuccessfullyType"
import RegisterFailedType from "../../types/rest/RegisterFailedType"
import ConfirmationSuccessfullyType from "../../types/rest/ConfirmationSuccessfullyType"
import ConfirmationFailedType from "../../types/rest/ConfirmationFailedType"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { FormattedMessage, useIntl } from "react-intl"
import ConfirmationTimer from "../../components/timerCountDown/TimerCountDown"
import { isLoggedIn, logout } from "../../store/authSlice"
import ForgetPasswordForm from "./ForgetPasswordForm"

const SignInPopup: React.FC<ISignInPopupProps> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { isLogged, verified, blocked, user } = useSelector(
    (state: RootState) => state.auth
  )
  const isLoggedInStatus = useSelector(isLoggedIn)

  const [isVisible, setIsVisible] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const [isForgetPassword, setIsForgetPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [identifier, setIdentifier] = useState("")
  const [fullName, setFullName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [password, setPassword] = useState("")
  const [codeConfirm, setCodeConfirm] = useState("")

  useEffect(() => {
    setIsVisible(true)
  }, [])

  const switchForm = () => {
    if(!isLoggedInStatus) {
      dispatch(logout())
    }
    setError(null)
    setIsLoading(false)
    setIsLogin(!isLogin)
  }

  const handleIdentifierChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value

    // Use a regular expression to only allow numbers
    const onlyNumbers = /^[0-9]*$/

    // Check that the input is only numbers and the length is less than or equal to 10
    if (onlyNumbers.test(value) && value.length <= 10) {
      // If it starts with '05' or it's not fully typed yet, set the state
      if (value.startsWith("05")) {
        setIdentifier(value)
      } else if (value.length < 2) {
        setIdentifier(value)
      } else {
        // Handle invalid input here, either by setting an error state
        // or ignoring the input
      }
    }
  }

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value)
  }

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value

    // Use a regular expression to only allow numbers
    const onlyNumbers = /^[0-9]*$/

    // Check that the input is only numbers and the length is less than or equal to 10
    if (onlyNumbers.test(value) && value.length <= 10) {
      // If it starts with '05' or it's not fully typed yet, set the state
      if (value.startsWith("05")) {
        setPhoneNumber(value)
      } else if (value.length < 2) {
        setPhoneNumber(value)
      } else {
        // Handle invalid input here, either by setting an error state
        // or ignoring the input
      }
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleCodeConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCodeConfirm(e.target.value)
  }

  const onClickForgetPassword = () => {
    setIsForgetPassword(true);
  }

  const confirmCodeFunc = async () => {
    if (isLoading) return
    if (!codeConfirm) {
      setError(formatMessage({ id: "Error.Message.MissingFields" }))
      return
    }
    setIsLoading(true)
    const phoneNumber = user?.phoneNumber

    if (phoneNumber) {
      const confirmationMessage:
        | ConfirmationSuccessfullyType
        | ConfirmationFailedType = await confirmUser(phoneNumber, codeConfirm)
      setIsLoading(false)

      if (
        "type" in confirmationMessage &&
        confirmationMessage.type === "failed"
      ) {
        setError(formatMessage({ id: confirmationMessage.message.id }))
      }
    }
  }

  const registerFunc = async () => {
    if (isLoading) return
    if (!fullName || !phoneNumber || !password) {
      setError(formatMessage({ id: "Error.Message.MissingFields" }))
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    const registerMessage: RegisterSuccessfullyType | RegisterFailedType =
      await register(fullName, phoneNumber, password)
    setIsLoading(false)
    if (registerMessage.type === "failed" && "message" in registerMessage) {
      setError(formatMessage({ id: registerMessage.message.id }))
    }
  }

  const loginFunc = async () => {
    if (isLoading) return
    if (!identifier || !password) {
      setError(formatMessage({ id: "Error.Message.MissingFields" }))
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    const loginMessage: LoginSuccessfullyType | LoginFailedType = await login(
      identifier,
      password
    )
    setIsLoading(false)
    if (loginMessage.type === "failed" && "message" in loginMessage) {
      setError(formatMessage({ id: loginMessage.message.id }))
    }
  }

  

  const renderCodeConfirmationForm = () => {
    return (
      <>
        <InnerContainerStyle>
          <PopupWindowTitleStyle>
            <FormattedMessage id="Label.Confirm" />
          </PopupWindowTitleStyle>
          <PopupWindowSubTitleStyle>
            <FormattedMessage id="Label.Confirm.SubLabel" />
          </PopupWindowSubTitleStyle>
          <ConfirmationTimer onResend={registerFunc}/>
        </InnerContainerStyle>
        <InputTextStyle
          id="codeConfirm"
          label={formatMessage({ id: "Label.ConfirmCode" })}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          variant="standard"
          value={codeConfirm}
          onChange={handleCodeConfirmChange}
        />
        <InnerContainerStyle>
          {error && <ErrorMessageStyle>{error}</ErrorMessageStyle>}
          <LoginButtonStyle
            onClick={confirmCodeFunc}
            value={formatMessage({
              id: (isLoading ? "Btn.Loading" : "Btn.Confirm")
            })}
            disabled={isLoading}
          />
        </InnerContainerStyle>
        <RegisterButtonStyle onClick={switchForm}>Login</RegisterButtonStyle>
      </>
    )
  }

  const renderLoginForm = () => {
    return (
      <>
        <InnerContainerStyle>
          <PopupWindowTitleStyle>
            <FormattedMessage id="Label.SignIn" />
          </PopupWindowTitleStyle>
          <PopupWindowSubTitleStyle>
            <FormattedMessage id="Label.SignIn.SubLabel" />
          </PopupWindowSubTitleStyle>
        </InnerContainerStyle>
        <InputTextStyle
          id="phone_number"
          label={formatMessage({ id: "Label.PhoneNumber" })}
          type="tel"
          inputMode="numeric"
          autoComplete="tel"
          variant="standard"
          value={identifier}
          onChange={handleIdentifierChange}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!/\d/.test(e.key) && e.key !== "Backspace") {
              e.preventDefault()
            }
          }}
        />
        <InputTextStyle
          id="password"
          label={formatMessage({ id: "Label.Password" })}
          type="password"
          autoComplete="current-password"
          variant="standard"
          value={password}
          onChange={handlePasswordChange}
        />
        <InnerContainerStyle>
          {error && <ErrorMessageStyle>{error}</ErrorMessageStyle>}
          <LoginButtonStyle
            onClick={loginFunc}
            value={formatMessage({
              id: isLoading ? "Btn.Loading" : "Btn.Login"
            })}
            disabled={isLoading}
          />
          <ForgetPasswordStyle onClick={onClickForgetPassword}>
            <FormattedMessage id="Btn.ForgetPassword" />
          </ForgetPasswordStyle>
        </InnerContainerStyle>
        <RegisterButtonStyle onClick={switchForm}>
          <FormattedMessage id="Btn.Register" />
        </RegisterButtonStyle>
      </>
    )
  }

  const renderRegisterForm = () => {
    return (
      <>
        <InnerContainerStyle>
          <PopupWindowTitleStyle>
            <FormattedMessage id="Label.Register" />
          </PopupWindowTitleStyle>
          <PopupWindowSubTitleStyle>
            <FormattedMessage id="Label.Register.SubLabel" />
          </PopupWindowSubTitleStyle>
        </InnerContainerStyle>
        <InputTextStyle
          id="fullName"
          label={formatMessage({ id: "Label.FullName" })}
          type="text"
          autoComplete="current-fullName"
          variant="standard"
          value={fullName}
          onChange={handleFullNameChange}
        />
        <InputTextStyle
          id="phone_number"
          label={formatMessage({ id: "Label.PhoneNumber" })}
          type="tel"
          inputMode="numeric"
          autoComplete="tel"
          variant="standard"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!/\d/.test(e.key) && e.key !== "Backspace") {
              e.preventDefault()
            }
          }}
        />
        <InputTextStyle
          id="password"
          label={formatMessage({ id: "Label.Password" })}
          type="password"
          autoComplete="current-password"
          variant="standard"
          value={password}
          onChange={handlePasswordChange}
        />
        <InnerContainerStyle>
          {error && <ErrorMessageStyle>{error}</ErrorMessageStyle>}
          <LoginButtonStyle
            onClick={registerFunc}
            value={formatMessage({
              id: isLoading ? "Btn.Loading" : "Btn.Register"
            })}
            disabled={isLoading}
          />
        </InnerContainerStyle>
        <RegisterButtonStyle onClick={switchForm}>
          <FormattedMessage id="Btn.Back" />
        </RegisterButtonStyle>
      </>
    )
  }

  const closeIsForgetPasswordPopup = () => {
    setIsForgetPassword(false);
  }

  const getRender = () => {
    if(isForgetPassword) {
      return <ForgetPasswordForm onClose={closeIsForgetPasswordPopup} />
    } else if (isLogin) {
      // return renderCodeConfirmationForm() //! this should be changed to ==> return renderLoginForm()
      return renderLoginForm() 
    } else {
      if (blocked || verified) {
        closeWindow()
      } else if (isLogged) {
        return renderCodeConfirmationForm()
      } else {
        return renderRegisterForm()
      }
    }
  }

  return (
    <BlurredBackGroundDiv>
    <ParentContainer>
      <BackPopupWindowStyle onClick={closeWindow}></BackPopupWindowStyle>
      <PopupWindowStyle isVisible={isVisible}>
        <FormContainerStyle>{getRender()}</FormContainerStyle>
      </PopupWindowStyle>
    </ParentContainer>
    </BlurredBackGroundDiv>
  )
}

export default SignInPopup
