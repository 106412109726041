import { useEffect, useRef, useState } from "react"
import CloseButton from "../../components/assets/icons/Close_Rectangle.svg"
import BookNowPanelProps from "./BookNowPanelProps"
import {
  CloseButtonStyle,
  CloseScrollButtonStyle,
  ScrollWindowContainerStyle,
  ScrollWindowBackgroundStyle,
  ServiceList,
  BookButton,
  Container,
  ServiceIcon,
  ServiceItem,
  ServiceName,
  Title,
  BookNowButtonContainer,
  ContainerDays,
  TimeContainer,
  DataSipDiv
} from "./Styles"
import {
  bookNow,
  editBook,
  getAvailableTimes
} from "../../services/BackendServices"
import { getServices } from "../../services/BackendServices"
import ConfirmMessage from "../../components/confirmMessage/ConfirmMessage"
import AvailableTimeType, {
  AvailableTime
} from "../../types/rest/AvailableTimeType"
import axios from "axios"
import AvailableTimeFailedType from "../../types/rest/AvailableTimeFailedType"
import { FormattedMessage, useIntl } from "react-intl"
import arrow_down_icon from "../../components/assets/icons/arrow_down_icon.svg"
import clock_icon from "../../components/assets/icons/clock_icon.svg"
import { getSelectedLanguage } from "../../store/languagesSlice"
import Box from "@mui/material/Box"
import { useSelector } from "react-redux"
import Calendar from "./Calendar"
import TimePicker from "./TimePicker"
import { selectDate } from "../../store/dateSelectionSlice"
import { set } from "date-fns"

interface ServiceItemProps {
  name: string
  icon: string
  id: string
  capacity: number
  mpc: number
}

interface SelectDialogProps {
  options: string[]
  onSelect: (option: string) => void
  open: boolean
  onClose: () => void
}

const SelectDialog: React.FC<SelectDialogProps> = ({
  options,
  onSelect,
  open,
  onClose
}) => {
  if (options.length === 0) {
    onClose()
    return <></>
  }
  return <></>
}

const BookNowPanel: React.FC<BookNowPanelProps> = ({
  closeFunc,
  isOpen,
  isEdit
}) => {
  const { formatMessage } = useIntl()
  // !this is the Decalration of the states
  const [selectedServices, setSelectedServices] = useState<ServiceItemProps[]>(
    []
  )
  const [services, setServices] = useState<any[]>([])
  const [selectedDDay, setSelectedDDay] = useState<any>()
  const [selTime, setSelTime] = useState<string>("")
  // const [DatesTimes,setDatesTimes] = useState<AvailableTimeType | null>(null)
  const [timesSlots, setTimesSlots] = useState<string[]>([])

  const [inProgress, setInProgress] = useState<boolean>(false)
  // let datesTimes = useAvailableTimes(inProgress,selectedServices[0]?.id,selectedDDay)

  const [selectedDay, setSelectedDay] = useState<AvailableTime>()
  const [selectedTime, setSelectedTime] = useState<string | null>(null)
  const bookNowEnable = useRef<boolean>(false)

  const [adultsSelected, setAdultsSelected] = useState<boolean>(true)
  const [isOpenTimeSelection, setIsOpenTimeSelection] = useState<boolean>(false)
  const ref = useRef(null)

  const [errorMessage, setErrorMessage] = useState<String | null>(null)
  const [successfullyMessage, setSuccessfullyMessage] = useState<String | null>(null)
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  const selectDay = (day: AvailableTime) => {
    if (inProgress) return
    setSelectedDay(day)
    if (day.timeList.length > 0) {
      const firstTimeInDay = day.timeList.at(0)
      if (firstTimeInDay) {
        setSelectedTime(firstTimeInDay)
      }
      bookNowEnable.current = true
    } else {
      setSelectedTime("")
      bookNowEnable.current = false
    }
  }

  const closePanel = () => {
    closeFunc()
  }

  const getTimes = () => {
    return <></>
  }

  const cancelFunc = () => {
    setInProgress(false)
    bookNowEnable.current = true
    closeFunc()
  }

  const bookNowButton = async () => {
    if (bookNowEnable.current) {
      setErrorMessage(null)
      setSuccessfullyMessage(null)
      setInProgress(true)
    }
  }

  //TODO fetch all the services from backend
  const fetchServices = async () => {
    try {
      const response = await axios.get(
        "https://alfena.bookify.co.il/api/services"
      )
      const services = response.data
      setServices(services)
      // TODO: Process the services data
    } catch (error) {
      console.error("Error fetching services:", error)
    }
  }

  useEffect(() => {
    fetchServices()
  }, [])

  interface IConfirmMessageProps {
    message: string
    onClose: () => void
  }

  useEffect(() => {
    // ? fetch the times for said day and service here
    if (selectedDDay && selectedServices) {
      console.log(selectedServices[0]?.id, selectedDDay)
      const fetchAvailableTimes = async () => {
        console.log("here")
        // ToDo - change the hardcoded service id in getAvailableTimes
        const availableTime: AvailableTimeFailedType | AvailableTimeType =
          await getAvailableTimes(selectedServices[0]?.id, selectedDDay)
        // console.log(availableTime)
        if (availableTime.statusCode === 200) {
          "body" in availableTime &&
            setTimesSlots(availableTime.body.availableTime[0].timeList)
        } else {
          setTimesSlots([])
        }
      }

      fetchAvailableTimes()
    }
  }, [selectedDDay, selectedServices])

  const toggleService = (serviceName: ServiceItemProps) => {
    setSelectedServices((prevSelected: ServiceItemProps[]) =>
      prevSelected.some((service) => service.name === serviceName.name)
        ? prevSelected.filter((service) => service.name !== serviceName.name)
        : [serviceName]
    )
    setSelTime("")
    setSelectedDDay(null)
    setTimesSlots([])
  }

  const toggleBookingOn = () => {
    if (selectedServices.length > 0 && selectedDDay && selTime) {
      setIsPopupOpen(true)
    }
  }

  const toggleBookingOff = () => {
    setIsPopupOpen(false)
    setSelectedServices([])
    setSelTime("")
    setSelectedDDay(null)
    setTimesSlots([])
    closeFunc()
    setErrorMessage(null)
    setSuccessfullyMessage(null)
    
  }

  const bookingNowFunc = async () => {
    try {
      const date = selectedDDay
      const time = selTime
      const isAdult = true
      const serviceId = selectedServices[0]?.id
      const bookingRespond = await bookNow(date, time, serviceId, isAdult)
      console.log("bookingRespond", bookingRespond)

      if ("error" in bookingRespond) {
        setErrorMessage(bookingRespond.message)
      } else {
        const date = selectedDDay
        const time = selTime
        const msg = formatMessage(
          { id: "bookingSuccessfullyMessage" },
          { date: date, time: time }
        )
        setSelectedTime(null)
        setSelectedDay(undefined)
        setSuccessfullyMessage(msg)
      }
    } catch (err) {

    } finally {
    }
  }

  const setDay = (day: string) => {
    setSelectedDDay(day)
    setInProgress(true)
    setTimesSlots([])
    setSelTime("")
  }

  const getTranslateService = () => <FormattedMessage id={selectedServices[0]?.name} />

  return (
    <>
      {isPopupOpen && (
        <ConfirmMessage
          headerText={formatMessage({ id: "header.confirm.booking" })}
          bodyText={formatMessage(
            {
              id: "confirmBookingMessage"
            },
            {
              serviceName: getTranslateService(),
              selectedDDay,
              selTime
            }
          )}
          message={errorMessage || successfullyMessage}
          isConfirmed={errorMessage !== null || successfullyMessage !== null}
          isPassed={successfullyMessage !== null}
          confirmFunc={bookingNowFunc}
          cancelFunc={toggleBookingOff}
        />
      )}

      {isOpen && <ScrollWindowBackgroundStyle onClick={closeFunc} />}

      <ScrollWindowContainerStyle className={isOpen ? "moving" : ""}>
        <CloseScrollButtonStyle>
          <CloseButtonStyle
            src={CloseButton}
            onClick={closePanel}
            onDrag={closePanel}
          />
        </CloseScrollButtonStyle>
        {/* //!choose service  */}
        <Container>
          <Title>
            <FormattedMessage id="choose.service" />
          </Title>
          <ServiceList>
            {services.map((service: ServiceItemProps) => (
              <ServiceItem
                key={service.name}
                selected={selectedServices.includes(service)}
                onClick={() => toggleService(service)}
              >
                <ServiceName>
                  <FormattedMessage id={service.name} />
                </ServiceName>
              </ServiceItem>
            ))}
          </ServiceList>
        </Container>

        {/* //! choose calendar day */}
        {selectedServices.length > 0 && <Calendar setDay={setDay} />}
        {selectedServices.length > 0 && selectedDDay && (
          <TimePicker setTime={setSelTime} times={timesSlots} />
        )}
        {selectedServices.length > 0 && selectedDDay && selTime && (
          <BookNowButtonContainer>
            <BookButton onClick={toggleBookingOn}>Book Now</BookButton>
          </BookNowButtonContainer>
        )}
      </ScrollWindowContainerStyle>
    </>
  )
}

export default BookNowPanel
