import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppointmentsType from '../types/rest/AppointmentType';

interface AppointmentsState {
    bookingList: AppointmentsType[];
}

const initialState: AppointmentsState = {
    bookingList: []
};

export const dateSelectionSlice = createSlice({
  name: 'bookingList',
  initialState,
  reducers: {
    setAppointments: (state, action: PayloadAction<AppointmentsType[]>) => {
        state.bookingList = action.payload;
    },
    reset: (state) => {
        state.bookingList = [];
    },
  },
});

export const getBookingList = (state: { appointments: {bookingList: AppointmentsType[]} }) => {
    return state.appointments.bookingList;
}

export const getDates = (state: { appointments: {bookingList: AppointmentsType[]} }) => {
    const date = state.appointments.bookingList.flatMap(day=>day.date);
    return date;
}

export const { setAppointments, reset } = dateSelectionSlice.actions;

export default dateSelectionSlice.reducer;