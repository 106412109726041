import { changeStatus, setToken } from "../store/authSlice";
import { store } from "../store/store";
import AvailableTimeFailedType from "../types/rest/AvailableTimeFailedType";
import AvailableTimeType from "../types/rest/AvailableTimeType";
import BookingData from "../types/rest/BookingData";
import BookingFailedType from "../types/rest/BookingFailedType";
import BookingListFailedType from "../types/rest/BookingListFailedType";
import BookingListSuccessfullyType from "../types/rest/BookingListSuccessfullyType";
import BookingSuccessfullyType from "../types/rest/BookingSuccessfullyType";
import ConfirmationFailedType from "../types/rest/ConfirmationFailedType";
import ConfirmationSuccessfullyType from "../types/rest/ConfirmationSuccessfullyType";
import ErrorResponseType from "../types/rest/ErrorResponseType";
import LoginFailedType from "../types/rest/LoginFailedType";
import LoginSuccessfullyType from "../types/rest/LoginSuccessfullyType";
import MeInformationSuccessfullyType from "../types/rest/MeInformationSuccessfullyType";
import MediaType from "../types/rest/MediaType";
import RegisterFailedType from "../types/rest/RegisterFailedType";
import RegisterSuccessfullyType from "../types/rest/RegisterSuccessfullyType";
import { login as loginStore } from "../store/authSlice"
import CancelBookingSuccessfullyType from "../types/rest/CancelBookingFailedType";
import CancelBookingFailedType from "../types/rest/CancelBookingFailedType";
import ReminderBookingSuccessfullyType from "../types/rest/ReminderBookingSuccessfullyType";
import ReminderBookingFailedType from "../types/rest/ReminderBookingFailedType";
import ShiftType from "../types/rest/ShiftType";
import ERRORRestRespondType from "../types/rest/ERRORRestRespondType";
import WorkingDayType from "../types/rest/WorkingDayType";
import WorkingScheduleType from "../types/rest/WorkingScheduleType";
import ShiftsType from "../types/rest/ShiftsType";
import RestRespondType from "../types/rest/RestRespondType";
import { SpecialDaysType } from "../types/rest/SpecialDayType";
import AppointmentsType from "../types/rest/AppointmentsType";
import EditedUserAppointment from "../types/rest/EditUserAppointment";
import DeleteAppointmentResponse from "../types/rest/DeleteAppointmentResponse";
import EditableAppointmentResponse from "../types/rest/EditableAppointmentResponse";
import ContactsResponse from "../types/rest/ContactsResponse";
import BlockStatusResponse from "../types/rest/BlockStatusResponse";
import VerifyStatusResponse from "../types/rest/VerifyStatusResponse";
import DeleteUserResponse from "../types/rest/DeleteUserResponse";
import HistoriesResponse from "../types/rest/HistoriesResponse";
import ForgetPasswordType from "../types/rest/ForgetPasswordType";
import ServicesResponseType from "../types/rest/ServicesResponseType";
import ResetPasswordRespondType from "../types/rest/ResetPasswordRespondType";

const BackBaseUri = "https://alfena.bookify.co.il";
// const BackBaseUri = "http://172.104.226.80:1337";
// const BackBaseUri = "http://192.168.68.118:8080";
// const BackBaseUri = "http://192.168.0.140:8080";

export default async function getMedia(groupName: string): Promise<MediaType[]> {
    const response = await fetch(`${BackBaseUri}/api/media-collections/${groupName}`);

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }
    const data: MediaType[] = await response.json();
    return data;
}

export async function login(identifier: string, password: string): Promise<LoginFailedType | LoginSuccessfullyType> {

    const response = await fetch(`${BackBaseUri}/auth/local`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier, password }),
    });

    if (response.status === 400) {
        const data: LoginFailedType = await response.json();
        const failResponse: LoginFailedType = {
            type: "failed",
            statusCode: data.statusCode,
            error: data.error,
            message: data.message,
            data: data.data,
        };

        return failResponse;
    }

    if (!response.ok) {
        throw new Error("Login failed");
    }

    const data = await response.json();
    const successResponse: LoginSuccessfullyType = data;

    const { jwt, user, confirmed, blocked, role } = data;
    // console.log(jwt, user, confirmed, blocked, role)

    store.dispatch(loginStore({
        user: user,
        token: jwt,
        role: role,
        blocked: blocked,
        verified: confirmed
    }));

    return successResponse;
}

export async function getAvailableTimes(serviceID: string, date: string): Promise<AvailableTimeFailedType | AvailableTimeType> {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;

    if (!token) {
        return {
            statusCode: 401,
            error: "ERROR",
            message: "Need To login first",
        };
    }

    const response = await fetch(`${BackBaseUri}/api/getAvailableTimes?service_id=${serviceID}&date=${date}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            "accept-language": selectedLanguage
        },
    });

    if (response.status === 400 || response.status === 401) {
        const data: AvailableTimeFailedType = await response.json();
        const failResponse: AvailableTimeFailedType = {
            statusCode: data.statusCode,
            error: data.error,
            message: data.message,
        };

        return failResponse;
    }

    if (!response.ok) {
        throw new Error("Login failed");
    }

    const data: AvailableTimeType = await response.json();

    return data;
}

export async function getBookingList(date: string): Promise<BookingListFailedType | BookingListSuccessfullyType> {
    const token = store.getState().auth.token;

    if (!token) {
        return {
            statusCode: 401,
            error: "ERROR",
            message: "Need To login first",
        };
    }

    const response = await fetch(`${BackBaseUri}/api/getBookingList?date=${date}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        },
    });

    if (response.status === 400 || response.status === 401) {
        const data: BookingListFailedType = await response.json();
        const failResponse: BookingListFailedType = {
            statusCode: data.statusCode,
            error: data.error,
            message: data.message,
        };

        return failResponse;
    }

    if (!response.ok) {
        throw new Error("Login failed");
    }

    const data: BookingListSuccessfullyType = await response.json();

    return data;
}

export async function register(fullName: string, phoneNumber: string, password: string): Promise<RegisterFailedType | RegisterSuccessfullyType> {
    try {
        const response = await fetch(`${BackBaseUri}/auth/local/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ fullName, phoneNumber, password }),
        });

        if (response.status === 400) {
            const registerFailed: RegisterFailedType = await response.json();
            return {
                ...registerFailed,
                type: "failed",

            };
        }

        if (!response.ok) {
            throw new Error("register failed");
        }

        const data: RegisterSuccessfullyType = await response.json();
        const { jwt, role, verified, blocked, user } = data;
        const successResponse: RegisterSuccessfullyType = {
            type: "success",
            jwt: jwt,
            user: user,
            role: role,
            verified: verified,
            blocked: blocked
        };

        store.dispatch(loginStore({
            user: user,
            token: jwt,
            role: role,
            blocked: false,
            verified: false
        }));

        return successResponse;

    } catch (error) {
        const failResponse: RegisterFailedType = {
            type: "failed",
            statusCode: 400,
            error: "Server is not responding",
            message: {
                id: "server.respond.error",
                message: "Server is not responding"
            },
            data: {
                id: "server.respond.error",
                message: "Server is not responding"
            }
        };
        return failResponse;
    }
}

export async function confirmUser(phoneNumber: string, confirmationCode: string): Promise<ConfirmationFailedType | ConfirmationSuccessfullyType> {
    try {
        const response = await fetch(`${BackBaseUri}/auth/confirmation/mobile-confirmation`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ phoneNumber, confirmationCode }),
        });

        if (response.status === 400) {
            const failResponse: ConfirmationFailedType = await response.json();
            return {
                ...failResponse,
                type: "failed"
            };
        }

        if (!response.ok) {
            throw new Error("register failed");
        }

        const data = await response.json();
        const successResponse: ConfirmationSuccessfullyType = data;

        const { user } = data;

        store.dispatch(changeStatus({
            blocked: user.blocked,
            verified: user.confirmed
        }));

        return successResponse;

    } catch (error) {
        const failResponse: ConfirmationFailedType = {
            type: "failed",
            statusCode: 400,
            error: "Server is not responding",
            message: {
                id: "server.respond.error",
                message: "Server is not responding"
            },
            data: {
                id: "server.respond.error",
                message: "Server is not responding"
            }
        };
        return failResponse;
    }
}

export async function isLoggedIn(): Promise<boolean> {
    const token = store.getState().auth.token;

    if (!token) {
        return false;
    }

    try {
        const response = await fetch(`${BackBaseUri}/api/users/me`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            return false;
        }

        const data = await response.json();
        const me: MeInformationSuccessfullyType = data;

        const { blocked, confirmed } = data;

        store.dispatch(changeStatus({
            blocked: blocked,
            verified: confirmed
        }));

        return true;
    } catch (error) {
        console.error("Error checking login status", error);
        return false;
    }
}

export async function bookNow(BookingDate: String, BookingTime: String, ServiceId: String, BookingIsAdult: boolean, BookingFullName?: String, BookingPhoneNumber?: String): Promise<BookingSuccessfullyType | BookingFailedType> {
    const token = store.getState().auth.token;
    const language = store.getState().languages.language;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/booknow`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "Accept-Language": language
            },
            body: JSON.stringify({
                BookingDate,
                BookingTime,
                BookingFullName,
                BookingPhoneNumber,
                isAdult: BookingIsAdult,
                serviceId: ServiceId
            }),
        });

        const bookingMessage: BookingSuccessfullyType | BookingFailedType = await response.json();

        if (!response.ok) {
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": bookingMessage.message
            };
        }

        return bookingMessage;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later."
        }
    }
}

export async function cancelBooking(bookingId: string, message: string): Promise<CancelBookingSuccessfullyType | CancelBookingFailedType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/cancelBooking`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                bookingId,
                message
            }),
        });

        const bookingMessage: CancelBookingSuccessfullyType | CancelBookingFailedType = await response.json();

        if (!response.ok) {
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": bookingMessage.message
            };
        }

        return bookingMessage;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later."
        }
    }
}

export async function reminderBooking(bookingId: string, message: string): Promise<ReminderBookingSuccessfullyType | ReminderBookingFailedType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/reminderBooking`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                bookingId,
                message
            }),
        });

        const bookingMessage: ReminderBookingSuccessfullyType | ReminderBookingFailedType = await response.json();

        if (!response.ok) {
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": bookingMessage.message
            };
        }

        return bookingMessage;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later."
        }
    }
}

export async function createShift(shiftName: string, fromHour: string, toHour: string, mpc: string): Promise<ShiftType | ERRORRestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/working-hours`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                shiftName,
                fromHour,
                toHour,
                mpc
            }),
        });

        const shiftResp: ShiftType | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in shiftResp) {
            const message = ("message" in shiftResp) ? shiftResp.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return shiftResp;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function deleteShift(shiftId: string): Promise<String | ERRORRestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/working-hours/${shiftId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        const shiftResp: ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in shiftResp) {
            const message = ("message" in shiftResp) ? shiftResp.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return "deleted";
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function getShifts(): Promise<ShiftsType | ERRORRestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/working-hours`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        const shiftsRest: ShiftsType | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in shiftsRest) {
            const message = ("message" in shiftsRest) ? shiftsRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return shiftsRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function getSchedule(): Promise<WorkingScheduleType | ERRORRestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/working-days`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        const scheduleRest: WorkingScheduleType | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function removeShiftFromSchedule(dayId: String, shiftId: String): Promise<WorkingDayType | ERRORRestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/working-days/${dayId}/shifts/${shiftId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        const scheduleRest: WorkingDayType | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function addShiftFromSchedule(dayId: String, shiftId: String): Promise<WorkingDayType | ERRORRestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/working-days/${dayId}/shifts`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                shiftId
            }),
        });

        const scheduleRest: WorkingDayType | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function updateShiftsFromSchedule(day: String, shiftIds: String[]): Promise<RestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/working-days/shifts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                day,
                shiftIds
            }),
        });

        const scheduleRest: ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return {
            "statusCode": response.status,
            "error": "ERROR",
            "message": "saved",
            "data": "saved"
        };;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function createSpecialDay(date: String, shiftIds: String[]): Promise<RestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/special-days`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                date,
                shiftIds
            }),
        });

        const scheduleRest: ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return {
            "statusCode": response.status,
            "error": "ERROR",
            "message": "saved",
            "data": "saved"
        };;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function getSpecialDays(): Promise<RestRespondType | SpecialDaysType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/special-days`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        const scheduleRest: ERRORRestRespondType | SpecialDaysType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}

export async function deleteSpecialDays(specialDayId: string): Promise<RestRespondType> {
    const token = store.getState().auth.token;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/special-days/${specialDayId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        const scheduleRest: RestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return {
            "statusCode": response.status,
            "error": "",
            "message": "deleted",
            "data": "deleted"
        };
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}


export async function getUserAppointments(): Promise<RestRespondType | AppointmentsType> {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/getUserAppointments`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "accept-language": selectedLanguage
            },
        });

        const scheduleRest: RestRespondType | AppointmentsType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}


export async function getContacts(fromIndex: number, range: number, search: string | null = null): Promise<ContactsResponse | ERRORRestRespondType> {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/contacts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "accept-language": selectedLanguage
            },
            body: JSON.stringify({
                index_start: fromIndex,
                range,
                search
            }),
        });

        const scheduleRest: ContactsResponse | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}


export async function changeBlockStatsForUser(phoneNumber: string, status: boolean): Promise<BlockStatusResponse | ERRORRestRespondType> {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/contacts/user-block-status`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "accept-language": selectedLanguage
            },
            body: JSON.stringify({
                phoneNumber,
                status
            }),
        });

        const scheduleRest: BlockStatusResponse | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}


export async function changeVerifyStatsForUser(phoneNumber: string, status: boolean): Promise<VerifyStatusResponse | ERRORRestRespondType> {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/contacts/confirmation`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "accept-language": selectedLanguage
            },
            body: JSON.stringify({
                phoneNumber,
                status
            }),
        });

        const scheduleRest: VerifyStatusResponse | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}


export async function deleteUserAccount(phoneNumber: string): Promise<DeleteUserResponse | ERRORRestRespondType> {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/contacts/delete-user`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "accept-language": selectedLanguage
            },
            body: JSON.stringify({
                phoneNumber
            }),
        });

        const scheduleRest: DeleteUserResponse | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}


export async function getHistories(fromIndex: number, range: number, search: string | null = null): Promise<HistoriesResponse | ERRORRestRespondType> {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;

    try {

        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/histories`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "accept-language": selectedLanguage
            },
            body: JSON.stringify({
                index_start: fromIndex,
                range,
                search
            }),
        });

        const scheduleRest: HistoriesResponse | ERRORRestRespondType = await response.json();

        if (!response.ok || "message" in scheduleRest) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return scheduleRest;
    }
    catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
}


export const editBook = async (appointmentId: String, date: string, time: string, isAdult: boolean): Promise<EditableAppointmentResponse | RestRespondType> => {
    const token = store.getState().auth.token;
    const selectedLanguage = store.getState().languages.language;
    const body = {
        "userAppointmentId": appointmentId,
        "BookingDate": date,
        "BookingTime": time,
        "isAdult": isAdult
    }

    try {
        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/editUserAppointment`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Accept-Language': selectedLanguage,
            },
            body: JSON.stringify(body)
        });

        const scheduleRest: RestRespondType | EditableAppointmentResponse = await response.json();
        if (!response.ok || ("statusCode" in scheduleRest)) {
            const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
            return {
                "statusCode": 504,
                "error": "ERROR",
                "message": message,
                "data": "Try Again Later."
            }
        }
        return scheduleRest;
    } catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
};

export async function resetPassword(phoneNumber: string, code: string, password: string, passwordConfirmation: string,): Promise<ResetPasswordRespondType | RestRespondType> {
    const selectedLanguage = store.getState().languages.language;
    const body = {
        "phoneNumber": phoneNumber,
        "password": password,
        "passwordConfirmation": passwordConfirmation,
        "code": code
    }

    try {
        const response = await fetch(`${BackBaseUri}/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': selectedLanguage,
            },
            body: JSON.stringify(body)
        });

        const respond: RestRespondType | ResetPasswordRespondType = await response.json();
        if (!response.ok || ("statusCode" in respond)) {
            const message = ("message" in respond) ? respond.message : "Try Again Later.";
            return {
                "statusCode": 504,
                "error": "ERROR",
                "message": message,
                "data": "Try Again Later."
            }
        }
        return respond;
    } catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
};

export async function forgetPassword(phoneNumber: string): Promise<ForgetPasswordType | RestRespondType> {
    const selectedLanguage = store.getState().languages.language;
    const body = {
        "phoneNumber": phoneNumber,
    }

    try {
        const response = await fetch(`${BackBaseUri}/auth/forgot-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': selectedLanguage,
            },
            body: JSON.stringify(body)
        });

        const respond: RestRespondType | ForgetPasswordType = await response.json();
        if (!response.ok || ("statusCode" in respond)) {
            const message = ("message" in respond) ? respond.message : "Try Again Later.";
            return {
                "statusCode": 504,
                "error": "ERROR",
                "message": message,
                "data": "Try Again Later."
            }
        }
        return respond;
    } catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
};

export async function getServices(): Promise<ServicesResponseType | RestRespondType> {
    const selectedLanguage = store.getState().languages.language;

    try {
        const response = await fetch(`${BackBaseUri}/api/services`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': selectedLanguage,
            },
        });

        const respond: RestRespondType | ServicesResponseType = await response.json();
        if (!response.ok || ("statusCode" in respond)) {
            const message = ("message" in respond) ? respond.message : "Try Again Later.";
            return {
                "statusCode": 504,
                "error": "ERROR",
                "message": message,
                "data": "Try Again Later."
            }
        }
        return respond;
    } catch (error) {
        return {
            "statusCode": 500,
            "error": "ERROR",
            "message": "Try Again Later.",
            "data": "Try Again Later."
        }
    }
};



export async function deleteAppointment(appointmentId: string): Promise<DeleteAppointmentResponse | RestRespondType> {
    const token = store.getState().auth.token;
    try {
        if (!token) {
            throw new Error("User is not authenticated");
        }

        const response = await fetch(`${BackBaseUri}/api/deleteUserAppointment/${appointmentId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error('Error in deleting appointment');
        }

        // const shiftResp: ERRORRestRespondType = await response.json();
        const result: DeleteAppointmentResponse | RestRespondType = await response.json();

        if (!response.ok || "message" in result) {
            const message = ("message" in result) ? result.message : "Try Again Later.";
            return {
                "statusCode": response.status,
                "error": "ERROR",
                "message": message,
                "data": message
            };
        }

        return result;

    } catch (error) {
        console.error('Error in deleteAppointment service:', error);
        throw error;
    }

    //     const scheduleRest: RestRespondType = await response.json();

    //     if (!response.ok || "message" in scheduleRest) {
    //         const message = ("message" in scheduleRest) ? scheduleRest.message : "Try Again Later.";
    //         return {
    //             "statusCode": response.status,
    //             "error": "ERROR",
    //             "message": message,
    //             "data": message
    //         };
    //     }

    //     return {
    //         "statusCode": response.status,
    //         "error": "",
    //         "message": "deleted",
    //         "data": "deleted"
    //     };
    // }
    // catch (error) {
    //     return {
    //         "statusCode": 500,
    //         "error": "ERROR",
    //         "message": "Try Again Later.",
    //         "data": "Try Again Later."
    //     }
    // }
}


