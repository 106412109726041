import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import authReducer from './authSlice';
import languagesReducer from './languagesSlice';
import dateSelectionReducer from './dateSelectionSlice';
import appointmentsReducer from './appointmentsSlice';
import messagesReducer from './messagesSlice';

import { PersistPartial } from "redux-persist/es/persistReducer";

export type RootState = {
  auth: ReturnType<typeof authReducer> & PersistPartial;
  languages: ReturnType<typeof languagesReducer> & PersistPartial;
  dateSelection: ReturnType<typeof dateSelectionReducer> & PersistPartial;
  appointments: ReturnType<typeof appointmentsReducer> & PersistPartial;
  messages: ReturnType<typeof messagesReducer> & PersistPartial;
};


const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

const languagesPersistConfig = {
  key: 'languages',
  storage: storage,
};

const dateSelectionPersistConfig = {
  key: 'dateSelection',
  storage: sessionStorage,
};

const appointmentsPersistConfig = {
  key: 'appointments',
  storage: sessionStorage,
};

const messagesPersistConfig = {
  key: 'messages',
  storage: sessionStorage,
};

const rootReducer = combineReducers<RootState>({
  auth: persistReducer(authPersistConfig, authReducer),
  languages: persistReducer(languagesPersistConfig, languagesReducer),
  dateSelection: persistReducer(dateSelectionPersistConfig, dateSelectionReducer),
  appointments: persistReducer(appointmentsPersistConfig, appointmentsReducer),
  messages: persistReducer(messagesPersistConfig, messagesReducer),
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);