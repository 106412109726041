import { Outlet } from "react-router-dom"
import Footer from "./footer/Footer"
import {
  ControllMainDiv,
  ControllerHeader,
  MenuContainer,
  OutletContainer
} from "./Style"
import { forwardRef, useEffect, useState } from "react"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert, { AlertProps } from "@mui/material/Alert"
import { useDispatch, useSelector } from "react-redux"
import { getMessage, getMessageStatus, reset } from "../../store/messagesSlice"
import { RootState } from "../../store/store"
import SignInPopup from "../../containers/signInPopup/SignInPopup"
import Menu from "../menuPage/menuPage"
import Header from "./header/Header"

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ControlLayout = () => {
  const { isLogged, verified, blocked, role } = useSelector(
    (state: RootState) => state.auth
  )
  const [isDisplayed, setIsDisplayed] = useState<boolean>(true)
  const dispatch = useDispatch()
  const message = useSelector(getMessage)
  const messageStatus = useSelector(getMessageStatus)
  const [openError, setOpenError] = useState(false)

  useEffect(() => {
    handleClose()
  }, [])

  useEffect(() => {
    setOpenError(!!message)
  }, [message])

  const openMenu = () => {
    setIsDisplayed(false)
  }

  const closeMenu = () => {
    setIsDisplayed(true)
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }
    dispatch(reset())
    setOpenError(false)
  }

  if (!isLogged || role !== "admin")
    return (
      <>
        <SignInPopup closeWindow={() => console.log("")} />
      </>
    )

  return (
    <>
      <MenuContainer>
        <Menu onClose={closeMenu} />
      </MenuContainer>
      <ControllMainDiv isDisplayed={isDisplayed}>
        <ControllerHeader>
          <Header onOpen={openMenu} />
        </ControllerHeader>
        <OutletContainer>
          <Outlet />
        </OutletContainer>
        <Footer />
        
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={messageStatus}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </ControllMainDiv>
    </>
  )
}

export default ControlLayout
