import styled from "styled-components";


interface ControllMainProps {
    isDisplayed: boolean;
}

export const OutletContainer = styled.div`
    background-color: #222532;
    height: calc(100% - 10em);
`

export const ControllerHeader = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding:15px;
    width: calc(100% - 30px);
`;

export const MenuContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const ControllMainDiv = styled.div<ControllMainProps>`
    position: fixed;  // Change from absolute to fixed
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    height: ${props => props.isDisplayed ? '100%' : '90%'};
    top: ${props => props.isDisplayed ? '0' : '5vh'};
    left: 0;
    transform: ${props => props.isDisplayed ? 'translateX(0)' : 'translateX(100%)'};
    overflow-y: none;  // Prevents vertical overflow: ;
    overflow-x: hidden;
    transition-property: height, top, transform;
    transition-duration: 0.5s, 0.5s, 0.5s;
    transition-timing-function: ease-out, ease-out, ease-out;
    transition-delay: ${props => props.isDisplayed ? '0.5s, 0.5s, 0s' : '0s, 0s, 0.5s'};
    background-color: #222532;
    width: 100%;

`;  
