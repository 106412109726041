import {
  MenuButtonContainer,
  MenuButtonStyle,
  MyAppointmentsTextHeader,
} from "./Header.styles"

import LanguagesController from "../../langaugesController/LanguagesController"
import { FormattedMessage } from "react-intl"

interface HeaderProps {
  onOpen:()=>void
}

const Header:React.FC<HeaderProps> = ({ onOpen}) => (
    <MenuButtonContainer>
      <MenuButtonStyle onClick={onOpen} />
      <MyAppointmentsTextHeader>
      <FormattedMessage id={`UserAppointments`} />
      </MyAppointmentsTextHeader>
      <LanguagesController />
    </MenuButtonContainer>
)

export default Header
