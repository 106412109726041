import {
  AdultChildImage,
  AdultChildMainDiv,
  BottomClientTabDiv,
  ButtonIconStyle,
  ButtonStyle,
  ButtonsContainerStyle,
  HrStyle,
  NameStyle,
  RowStyle,
  ServiceStyle,
  StyledDeleteIcon,
  StyledNotificationIcon,
  StyledProfileIcon,
  StyledWhatsappIcon,
  TableContainerStyle,
  TimeStyle,
  TopClientTabDiv
} from "./Styles"

import childLogo from "../../assets/icons/ChildLogo.svg"
import adultLogo from "../../assets/icons/adultLogo.svg"
import cancelIcon from "../../assets/icons/cancelIcon.svg"
import ReminderIcon from "../../assets/icons/ReminderIcon.svg"
import userDetailsIcon from "../../assets/icons/userDetailsIcon.svg"

import { useDispatch, useSelector } from "react-redux"
import { getSelectedDate } from "../../../store/dateSelectionSlice"
import { useEffect, useState } from "react"
import {
  getBookingList,
  setAppointments
} from "../../../store/appointmentsSlice"
import {
  cancelBooking,
  reminderBooking
} from "../../../services/BackendServices"
import { getBookingList as fetchBookingList } from "../../../services/BackendServices"
import BoxWithSender from "../../messageBoxes/boxWithSender/BoxWithSender"
import { AppointmentType } from "../../../types/rest/AppointmentType"
import UserInformationBox from "../../messageBoxes/userInformationBox/UserInformationBox"
import ReminderBox from "../../messageBoxes/reminderBox/ReminderBox"
import { FormattedMessage } from "react-intl"
import { RootState } from "../../../store/store"
import moment from "moment"

const ClientsTable = () => {
  const dispatch = useDispatch()
  const selectedDay = useSelector(getSelectedDate)
  const appointments = useSelector(getBookingList)
  const { date } = useSelector((state: RootState) => state.dateSelection);
  const [boxName, setBoxName] = useState<string | undefined>(undefined)
  const [appointment, setAppointment] = useState<AppointmentType | undefined>(
    undefined
  )

  //print the appointments

  const getSelectedDay = () => {
    if (selectedDay === undefined && appointments.length > 0) {
      return appointments[0].appointments
    }

    const selectedDayList = appointments.find((day) => day.date === selectedDay)
    if (selectedDayList == null) return []
    return selectedDayList.appointments
  }

  const sendReminder = async (message: string) => {
    if (appointment) {
      const reminderResponed = await reminderBooking(appointment.id, message)
      if (reminderResponed.statusCode === 200) {
        // ToDo - Successfully Message Box
      } else {
        // ToDo - Failed Message Box
      }
      setBoxName(undefined)
    }
  }

  const cancelAppointment = async (appointmentId: string, message: string) => {
    const cancelResponed = await cancelBooking(appointmentId, message)
    if (cancelResponed.statusCode === 200) {
      // ToDo - Successfully Message Box
      await refreshBookingList()
    } else {
      // ToDo - Failed Message Box
    }
    setBoxName(undefined)
  }

  const refreshBookingList = async () => {
    let selectedDate = date;
    if(selectedDate == null) {
      const currentDate: Date = moment.tz("Asia/Jerusalem").toDate()
      selectedDate = formatDate(currentDate);
    }
    const bookingList = await fetchBookingList(selectedDate)
    if ("appointments" in bookingList) {
      dispatch(setAppointments(bookingList.appointments))
    } else {
      dispatch(setAppointments([]))
    }
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    // Pad the month and day with a leading zero if they are less than 10
    const formattedMonth = month < 10 ? `0${month}` : month
    const formattedDay = day < 10 ? `0${day}` : day

    return `${year}-${formattedMonth}-${formattedDay}`
  }

  const displayBox = () => {
    switch (boxName) {
      case "BoxWithSender":
        return createCancelBox()
      case "UserInformationBox":
        return createUserInformationBox()
      case "ReminderBox":
        return createReminderBox()
    }
  }

  const createCancelBox = () => {
    if (appointment) {
      const message =
        `Hello ${appointment.user.fullName}, Your appointment at ` +
        `${appointment.date} / ${appointment.time} was canceled.`
      return (
        <BoxWithSender
          title="cancel_appointment"
          close={closeBox}
          message={message}
          confirm={(message) => cancelAppointment(appointment.id, message)}
        />
      )
    }
  }

  const createUserInformationBox = () => {
    if (appointment) 
      console.log(appointment.user)
    if (appointment) {
      return <UserInformationBox close={closeBox} user={appointment.user} />
    }
  }

  //TODO alter message to be formated text to en ar he
  const createReminderBox = () => {
    if (appointment) {
      const message =
        `Hello ${appointment.user.fullName}, this is reminder that ` +
        `your appointment at ${appointment.time}`
      return (
        <ReminderBox
          message={message}
          confirm={sendReminder}
          close={closeBox}
        />
      )
    }
  }

  const setBox = (boxName: string, appointment: AppointmentType) => {
    setAppointment(appointment)
    setBoxName(boxName)
  }

  const closeBox = () => {
    setBoxName(undefined)
  }

  return (
    <>
      {displayBox()}
      <TableContainerStyle>
        {getSelectedDay().map((appointment) => {
          return (
            <RowStyle>
              <TopClientTabDiv>
                <NameStyle>{appointment.user.fullName}</NameStyle>
                <AdultChildMainDiv>
                  {/* {(appointment.isAdult)?<>Adult <AdultChildImage src={adultLogo}/></>:<>Child <AdultChildImage src={childLogo}/></>} */}
                  <ServiceStyle>
                    <FormattedMessage id={appointment.service.name} />
                  </ServiceStyle>
                </AdultChildMainDiv>
              </TopClientTabDiv>
              <BottomClientTabDiv>
                <ButtonsContainerStyle>
                  {/* //! the row for shortcut buttons for each opp linking the client */}
                  <ButtonStyle
                    target="_blank"
                    href={`https://wa.me/${appointment.user.phoneNumber}`}
                  >
                    <StyledWhatsappIcon/>
                  </ButtonStyle>
                  <ButtonStyle>
                    <StyledNotificationIcon
                      onClick={() => setBox("ReminderBox", appointment)}
                    />
                  </ButtonStyle>
                  <ButtonStyle>
                    <StyledProfileIcon onClick={() => setBox("UserInformationBox", appointment)}/>
                  </ButtonStyle>
                  <ButtonStyle>
                    <StyledDeleteIcon onClick={() => setBox("BoxWithSender", appointment)}/>
                  </ButtonStyle>
                </ButtonsContainerStyle>
                {/* <HrStyle /> */}
                <TimeStyle>{appointment.time}</TimeStyle>
              </BottomClientTabDiv>
            </RowStyle>
          )
        })}
      </TableContainerStyle>
    </>
  )
}

export default ClientsTable
