import React, { useState } from "react"
import {
  BackDivBlurred,
  BackPopupWindowStyle,
  BackgroundPopupStyle,
  ButtonsContainerStyle,
  CancelButtonStyle,
  CloseButtonStyle,
  ConfirmButtonStyle,
  ERRORMessageStyle,
  MessageBodyStyle,
  MessageContainerStyle,
  MessageHeaderStyle,
  MessageTextStyle,
  OkLogo,
  PopupWindowStyle,
  SuccessfullyMessageStyle
} from "./Styles"
import IConfirmMessageProps from "./IConfirmMessageProps"
import { FormattedMessage } from "react-intl"
import okLogo from "../assets/icons/OkLogo2.svg"
const ConfirmMessage: React.FC<IConfirmMessageProps> = ({
  headerText,
  bodyText,
  message,
  isPassed,
  isConfirmed,
  confirmFunc,
  cancelFunc
}) => {
  const [Locked, setLocked] = useState(false)

  const AddDelay=async ()=>{
    if(Locked) return
    setLocked(true)
    await confirmFunc()
    setLocked(false)
  }

  const checkProgress = ()=>{
    if(Locked) return
    cancelFunc()
  }
  return (
    <BackDivBlurred>
      <BackgroundPopupStyle onClick={checkProgress} />
      <PopupWindowStyle isVisible={true}>
        <MessageContainerStyle>
          {(isConfirmed && (
            <>
              {(!isPassed && (
                <ERRORMessageStyle>{message}</ERRORMessageStyle>
              )) || (
                <SuccessfullyMessageStyle>{message}</SuccessfullyMessageStyle>
              )}
              <CloseButtonStyle onClick={checkProgress}>
                <FormattedMessage id="close" />
              </CloseButtonStyle>
            </>
          )) || (
            <>
              <MessageTextStyle>
                <MessageHeaderStyle>{headerText}</MessageHeaderStyle>
                <MessageBodyStyle>{bodyText}</MessageBodyStyle>
              </MessageTextStyle>
              <ButtonsContainerStyle>
                <ConfirmButtonStyle onClick={AddDelay}>
                  <FormattedMessage id="confirm" />
                </ConfirmButtonStyle>
                <CancelButtonStyle onClick={checkProgress}>
                  <FormattedMessage id="cancel" />
                </CancelButtonStyle>
              </ButtonsContainerStyle>
            </>
          )}
        </MessageContainerStyle>
      </PopupWindowStyle>
      <BackPopupWindowStyle onClick={checkProgress} />
    </BackDivBlurred>
  )
}

export default ConfirmMessage
