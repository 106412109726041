import React, { useState, useEffect } from "react"
import {
  HistoryMainContainer,
  UserDataContainer,
  UserDataItem,
  UserDataImage,
  UserDataIdContainer,
  UserDataType,
  UserDataTimeStamp,
  UserDataOperaion
} from "./Styles"
import adminLogo from "../../components/assets/icons/admin.svg"
import userLogo from "../../components/assets/icons/user.svg"
import adminAt from "../../components/assets/icons/adminArrow.svg"
import adminFor from "../../components/assets/icons/adminDone.svg"
import { getHistories } from "../../services/BackendServices"
import { HistoryRecord } from "../../types/rest/HistoriesResponse"
import { FormattedMessage } from "react-intl"

interface HistoryRecordProps {
  data: HistoryRecord
}
const UserTemplate: React.FC<HistoryRecordProps> = ({
  data
}: {
  data: HistoryRecord
}) => {
  const formattedTimestamp = new Date(data.timeStamp).toLocaleString("en-US", {
    timeZone: "Asia/Jerusalem",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Set hour12 to false to display hours as 0-24
  })

const formattedOperationTimestamp = new Date(data.operation).toLocaleString(
  "en-US",
  {
    timeZone: "Asia/Jerusalem",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Set hour12 to false to display hours as 0-24
  }
)

  return (
    <UserDataItem key={data.userId}>
      
      <UserDataIdContainer><UserDataImage
        src={data.byUser ? userLogo : adminLogo}
        alt="User Image"
      />{data.userId}</UserDataIdContainer>
      <UserDataType>
        <FormattedMessage id={`history.${data.type}`} />
      </UserDataType>
      <UserDataTimeStamp>
        <UserDataImage src={adminAt} alt="adminFor" />
        <FormattedMessage id={`history.for`} />
        {" : "}
        {data.operation}
      </UserDataTimeStamp>
      <UserDataTimeStamp>
      <UserDataImage src={adminFor} alt="adminFor" />
        <FormattedMessage id={`history.at`} />
        {" : "}
        {formattedTimestamp}
      </UserDataTimeStamp>
    </UserDataItem>
  )
}

const History = () => {
  const [userData, setUserData] = useState<HistoryRecord[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHistories(0, 10)
        if (!("message" in response)) {
          setUserData(response.histories)
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [])

  return (
    <HistoryMainContainer>
      {userData.map((data) => (
        <UserTemplate data={data} key={data.userId} />
      ))}
    </HistoryMainContainer>
  )
}

export default History
