import styled from "styled-components";

export const MainControllerContainer = styled.div`
height: 100%;
`;


export const ControllerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    `

export const TableStyle = styled.div`
height: 100%;
width: 100%;
    
`

export const HeaderStyle = styled.div`
    /* height: 50px; */
`

export const RowsStyle = styled.div`
    overflow-y: auto;
    height: 100%;
`