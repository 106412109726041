import styled from 'styled-components';
import menu_button from "../../assets/icons/Ham_button.svg"

export const StyleHeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background: linear-gradient(180deg, #222532 0%, rgba(34, 37, 50, 0.87) 39.06%, rgba(34, 37, 50, 0.42) 72.92%, rgba(34, 37, 50, 0) 100%);
`

export const StyleHeaderLogo = styled.img`
    width: 60%;
    max-width: 286px;
`

export const StyleLogo = styled.img`
    position: static;
    width: 60%;
    max-width: 286px;
    top: 10em;
    /* margin-left: auto; */
    /* margin-right: auto; */
`

export const MenuContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const MenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* padding: 20px 20px ; */
  position: relative;
  top: 0;
  width: 100%;
  height:3em;
  align-items: center;
  direction: ltr;
`

export const MenuButtonStyle = styled.img.attrs({ src: menu_button })`
  /* z-index:1; */
  /* padding: 2px 2px ; */
  /* right: 0; */
  /* position: fixed; */
`
export const MiddleAdminLogo = styled.img`
    position: relative;
    width: 4em;
    height: 4em;

`;