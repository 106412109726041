import { ControllerContainer, HeaderStyle, MainControllerContainer, RowsStyle, TableStyle } from "./Styles";
import WeekHeader from "../../components/calendar/weekHeader/WeekHeader";
import ClientsTable from "../../components/calendar/clientsTable/ClientsTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useEffect } from "react";
import { setAppointments } from "../../store/appointmentsSlice";
import { getBookingList as fetchBookingList } from "../../services/BackendServices"
import BookingButton from "../../components/bookingButton/BookingButton";
import moment from "moment";

const ControlPage = () => {
    const dispatch = useDispatch();
    const { role, isLogged } = useSelector((state: RootState) => state.auth);
    const { date } = useSelector((state: RootState) => state.dateSelection);

    useEffect(() => {
        if (role === "admin") {
            fetchData();

            const intervalId = setInterval(async () => {
                fetchData();
            }, 10000);

            return () => clearInterval(intervalId);
        }
    }, [role, isLogged, date]);

    const fetchData = async () => {
        let selectedDate = date;
        if(selectedDate == null) {
            const currentDate: Date = moment.tz("Asia/Jerusalem").toDate()
            selectedDate = formatDate(currentDate);
        }
        
        const bookingList = await fetchBookingList(selectedDate);
        
        if ("appointments" in bookingList) {
            dispatch(setAppointments(bookingList.appointments));
        }
        else {
            dispatch(setAppointments([]));
        }
    }

    const formatDate = (date: Date) => {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
    
        // Pad the month and day with a leading zero if they are less than 10
        const formattedMonth = month < 10 ? `0${month}` : month
        const formattedDay = day < 10 ? `0${day}` : day
    
        return `${year}-${formattedMonth}-${formattedDay}`
      }

    return (
        <MainControllerContainer>
            <ControllerContainer>
                <TableStyle>
                    <HeaderStyle>
                        <WeekHeader /> {/* //* this is the days week header on top */ }
                    </HeaderStyle>
                    <RowsStyle>
                        <ClientsTable />
                    </RowsStyle>
                </TableStyle>
            </ControllerContainer>
            {/* <BookingButton /> */}
        </MainControllerContainer>
    )
}

export default ControlPage;