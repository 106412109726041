import { Outlet } from "react-router-dom"

import Header from "./header/Header"
import Footer from "./footer/Footer"
import {
  LowerLayoutContainer,
  MainLayoutContainer,
  MenuContainer
} from "./Styled"
import { useState } from "react"
import Menu from "../menuPage/menuPage"

const Layout = () => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(true)

  const openMenu = () => {
    setIsDisplayed(false)
  }

  const closeMenu = () => {
    setIsDisplayed(true)
  }

  return (
    <>
      <MenuContainer>
        <Menu onClose={closeMenu} />
      </MenuContainer>
        <MainLayoutContainer isDisplayed={isDisplayed}>
          <Header onOpen={openMenu} />
          <Outlet />
          <Footer />
        </MainLayoutContainer>
    </>
  )
}

export default Layout
