import { useState } from "react"
import AdminMiddleLogo from "../../assets/icons/MenuLogoLeft.svg"
import {
  MenuButtonContainer,
  MenuButtonStyle,
  MiddleAdminLogo,
  StyleHeaderContainer,
  StyleHeaderLogo,
  
} from "./Header.styles"
import LanguagesController from "../../langaugesController/LanguagesController"
interface HeaderProps {
  onOpen:()=>void
}

const Header:React.FC<HeaderProps> = ({ onOpen}) => (
  
    <MenuButtonContainer>
      <MenuButtonStyle onClick={onOpen} />
      <MiddleAdminLogo src={AdminMiddleLogo} />
      <LanguagesController />
    </MenuButtonContainer>
  
)

export default Header
