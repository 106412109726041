import styled from 'styled-components';
import menu_button from "../../assets/icons/Ham_button.svg"

export const StyleHeaderContainer = styled.div`
    
    display: flex;
    justify-content: center;
    width: 100%;
    background: linear-gradient(180deg, #222532 0%, rgba(34, 37, 50, 0.87) 39.06%, rgba(34, 37, 50, 0.42) 72.92%, rgba(34, 37, 50, 0) 100%);
`

export const StyleHeaderLogo = styled.img`
    position: absolute;
    width: 60%;
    max-width: 286px;
    top: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const StyleLogo = styled.img`
    position: static;
    width: 60%;
    max-width: 286px;
    top: 10em;
    /* margin-left: auto; */
    /* margin-right: auto; */
`


export const MenuButtonContainer = styled.div`
  position: absolute;
  direction:ltr;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  /* padding: 20px 20px ; */
  /* position: relative; */
  width: calc(100% - 1em);
  padding: 1em 0.5em;
  align-items: center;
  align-self: center;
  /* height:50px; */
  top: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
`

export const MenuButtonStyle = styled.img.attrs({ src: menu_button })`
  position: relative;
  padding: 0px;
`
