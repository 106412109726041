import TextField from "@mui/material/TextField";
import styled from "styled-components";

export const ContainerBackgroundStyle = styled.div`
    position: absolute;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
`

export const BoxContainer = styled.div`
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #c5a496;
    border: 2px solid #222532;
    border-radius: 10px;
    width:80%;
    font-family: 'Jost', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
`

export const BoxTitleStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;
`

export const BoxBodyStyle = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
`

export const BoxMessageStyle = styled(TextField)`
    width: 80%;
`

export const OptionsBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`

export const ConfirmBoxStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #222532;
    color: #c5a496;
    border-radius: 30px ;
    width: 170px;
    height: 50px;

    &:hover {
        background-color: green;
        color: #222532;
        cursor: pointer;
    }
`

export const BoxCloseBoxStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #222532;
    color: #c5a496;
    border-radius :30px;
    width: 170px;
    height: 50px;
    
    &:hover {
        background-color: red;
        color: #222532;
        cursor: pointer;
    }
`