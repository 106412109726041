// src/Calendar.tsx
import React, { useState } from 'react';
import { format, addMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay, isAfter, subDays, isBefore } from 'date-fns';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const CalendarWrapper = styled.div`
  font-family: 'Jost', sans-serif;
  background-color: rgba(28, 28, 28,0.8);

  /* background-color: #c5a496; */
  padding: 10px;
  border-radius: 10px;
  width: auto;
  margin: 10px ;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 10px;
`;

const Icon = styled.div`
  cursor: pointer;
  color: #fff;

`;

const DaysRow = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  
`;

interface ColProps {
  selected?: boolean;
  disabled?: boolean;
}

const Col = styled.div<ColProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 2px;
  cursor: pointer;
  background-color: ${props => (props.selected ? '#8b6a61' : '#fff')};
  color: ${props => (props.selected ? '#fff' : props.disabled ? '#b0a9a8' : '#000')};
  border-radius: 50%;
  transition: background-color 0.3s;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${props => (props.disabled ? '#fff' : '#8b6a61')};
  }
`;

const Today = styled.span`
  font-weight: bold;
`;

interface CalendarProps {
  setDay: (day:string ) => void;
}

const Calendar: React.FC<CalendarProps> = ({setDay}) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const startDate = startOfWeek(startOfMonth(currentDate));
  const endDate = endOfWeek(endOfMonth(currentDate));

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const prevMonth = () => {
    setCurrentDate(subDays(currentDate, 30));
  };

  const onDateClick = (day: Date) => {
    const oneWeekFromToday = addDays(new Date(), 14);
    if ((isAfter(day, new Date()) || isSameDay(day, new Date())) && isBefore(day, oneWeekFromToday)) {
      setSelectedDate(day);
      setDay(format(day, 'yyyy-MM-dd'));
    }
    else{
      setSelectedDate(null);
      setDay("");
    }
  };

  const renderHeader = () => {
    return (
      <Header>
        <Icon onClick={prevMonth}>⇐</Icon>
        <div>
        <FormattedMessage id={format(currentDate, 'MMMM')} />
        <FormattedMessage id="  " />
        {format(currentDate, "yyyy")}</div>
        <Icon onClick={nextMonth}>⇒</Icon>
      </Header>
    );
  };

  const renderDays = () => {
    const days = [];
    const dateFormat = 'eee'; // Shortened format to get first three letters of the day
    let startDate = startOfWeek(currentDate);
  
    for (let i = 0; i < 7; i++) {
      days.push(
        <Col key={i} >
          <FormattedMessage id={format(addDays(startDate, i), dateFormat)} />
        </Col>
      );
    }
    return <DaysRow>{days}</DaysRow>;
  };
  

  const renderCells = () => {
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <Col
            key={day.toString()}
            selected={selectedDate !== null && isSameDay(day, selectedDate)}
            disabled={!isSameMonth(day, currentDate) || (!isAfter(day, new Date()) && !isSameDay(day, new Date())) || !isBefore(day, addDays(new Date(), 14))}
            onClick={() => onDateClick(cloneDay)}
          >
            <span className={`${isSameDay(day, new Date()) ? 'today' : ''}`}>{formattedDate}</span>
          </Col>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Row key={day.toString()}>
          {days}
        </Row>
      );
      days = [];
    }
    return <div>{rows}</div>;
  };

  return (
    <CalendarWrapper>
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </CalendarWrapper>
  );
};

export default Calendar;
