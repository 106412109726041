import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import SignInPopup from '../signInPopup/SignInPopup';

const SignInPage = () => {
    const navigate = useNavigate();
    const { isLogged, verified } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (isLogged && verified) {
            routeToHome(); // Navigate to home page if logged in
        }
    }, [isLogged, verified])

    const routeToHome = () => {
        navigate('/');
    }

    return <SignInPopup closeWindow={routeToHome} />;
};

export default SignInPage;
