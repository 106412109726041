import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportedLanguageType } from '../types/rest/SupportedLanguageType';

interface Language {
    language: SupportedLanguageType;
}

const initialState: Language = {
    language: 'He',
};

export const LanguageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<{ language: SupportedLanguageType }>) => {
      if (action.payload.language !== null) {
        state.language = action.payload.language;
      }
    },
  },
});

export const getSelectedLanguage = (state: { languages: Language }) => {
    const language = state.languages.language;
    return language;
}

export const { changeLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;