import { ChangeEvent, useState } from "react";
import IBoxProps from "./IBoxProps";
import { BoxBodyStyle, BoxCloseBoxStyle, BoxContainer, BoxMessageStyle, BoxTitleStyle, ConfirmBoxStyle, ContainerBackgroundStyle, OptionsBoxContainer } from "./Style";
import { FormattedMessage } from "react-intl";

const ReminderBox: React.FC<IBoxProps> = ({ message, confirm, close }) => {
    const [messageValue, setMessageValue] = useState<string>(message);
    const [lockButton, setLockButton] = useState(false);

    const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMessageValue(event.target.value);
    }

    const confirmFunc = (message: string) => {
        setLockButton(true);
        confirm(message);
    }

    return (
        <>
            <ContainerBackgroundStyle />
            <BoxContainer>
                <BoxTitleStyle>
                    <FormattedMessage id="send_reminder" />
                </BoxTitleStyle>
                <BoxBodyStyle>
                    <BoxMessageStyle
                        id="Message_Box"
                        label="Message"
                        multiline
                        defaultValue={message}
                        onChange={handleMessageChange}
                        rows={4}
                    />
                </BoxBodyStyle>
                <OptionsBoxContainer>
                    <ConfirmBoxStyle onClick={() => confirmFunc(messageValue)}>
                        <FormattedMessage id="confirm" />
                    </ConfirmBoxStyle>
                    <BoxCloseBoxStyle onClick={() => !lockButton && close()}>
                        <FormattedMessage id="close" />
                    </BoxCloseBoxStyle>
                </OptionsBoxContainer>
            </BoxContainer>
        </>
    )
}

export default ReminderBox;