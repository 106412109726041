import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DateSelectionState {
    date: string | null;
}

const initialState: DateSelectionState = {
    date: null
};

export const dateSelectionSlice = createSlice({
  name: 'dateSelection',
  initialState,
  reducers: {
    selectDate: (state, action: PayloadAction<string>) => {
        state.date = action.payload;;
    },
    reset: (state) => {
        state.date = null;
    },
  },
});

export const getSelectedDate = (state: { dateSelection: DateSelectionState }) => {
    const date = state.dateSelection.date;
    return date;
}

export const { selectDate, reset } = dateSelectionSlice.actions;

export default dateSelectionSlice.reducer;