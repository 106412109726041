import styled from 'styled-components';

export const TimerMainContainer = styled.div`
    width: 60%;
    color:lightgray;
    text-align: center;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ResendButton = styled.button`
    background-color:transparent;
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    border:none;
    color:#194C57;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;
