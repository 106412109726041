import styled from "styled-components";

interface TimeContainerProps {
    selected: boolean;
}

export const TimesListContainer = styled.div`
    display: flex;
    align-items: center;
    /* width:inherit; */
    gap: 10px;
    height: 50px;
    padding-left: 10px;
    overflow-x: auto;
    font-size: 15px;
    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Prevent text selection */
    user-select: none;
    &:last-child{
        padding-right: 5px;
    }
`

export const TimeContainer = styled.div<TimeContainerProps>`
    padding: 5px 10px;
    background-color: ${(props) => (props.selected ? '#4CAF50' : '#222532')};
    color: #FFFFFF;
    border-radius: 7px;
`