import styled from "styled-components";

interface boxContainerProps {
    boxSize: "big" | "small"
}

export const BoxContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 8cm;
    height: 60%;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    font-family: 'Jost',sans-serif;
    font-style: normal;
    color: #222532;
    z-index: 3;
`

export const HeaderStyle = styled.div`
    display: flex;
    justify-content: space-between;
    height: 50px;
    font-weight: 400;
    font-size: 20px;
    line-height: 39px;
`

export const DayOptionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.className === "selected" ? "#8b6a61" : "#c5a496"};
    border-radius: 90px;
    width: 50px;
    height: 50px;

    cursor: pointer;
`

export const BoxBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #c5a496;
    height: 100%;
    font-weight: 700;
    font-size: 27px;
    line-height: 39px;
    border-radius: 20px;
    border:0.5px solid black;
`

export const BoxTitleStyle = styled.div`
    display: flex;
    justify-content: center;
    /* margin: 10px 0; */
`

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 381px;
`

export const SubmitContainer = styled.div`
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
`

export const SubmitButtonStyle = styled.button`
    height: 50px;
    max-width: 200px;
    width: 50%;
    background-color: #222532;
    color: #FFFFFF;
    border-radius: 30px;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 20px;
`

export const ShiftsContainer = styled.div`
    display: flex;
    align-items: center;
`

export const ShiftsIconStyle = styled.div`
    width: 75px;
`

export const ShiftsListContainer = styled.div`
    width: calc(100% - 75px);
`

export const OptionsListContainer = styled.div`
    display: flex;
    gap: 10px;
    /* background-color: aqua; */
    padding: 10px 0;
`

export const ShiftContainer = styled.div`
    background-color: antiquewhite;
    padding: 5px 15px;
    border-radius: 10px;
    overflow-x: auto;
`

export const TableStyle = styled.table`
    width: 100%;

    td, th {
        text-align: center;
    }

    th {
        background-color: #998769;
        color: #222532;
        padding: 5px 0;
        font-size: 20px;
    }

    tr:nth-child(even) {
        background-color: #8b6a61;
        font-size: 15px;
    }

    tr:nth-child(odd) {
        background-color: #8b6a61;
        font-size: 15px;
    }
`;

export const TableHeaderStyle = styled.thead`

`

export const TableBodyStyle = styled.div`
  display: block; 
  height: 240px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  user-select: none; /* Standard syntax */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
  }
`;

export const TRStyle = styled.tr`
  display: table; 
  width: 100%; 
  table-layout: fixed;
`;

export const THStyle = styled.th`
    width: 29%;
`

export const THDeleteStyle = styled.th`
    width: 40px;
`

export const TDStyle = styled.td`
    /* width: 110px; */
`

export const TDDeleteStyle = styled.td`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 63px;
`

export const DeleteButtonStyle = styled.img`
    width: 50%;
    height: 50%;
    cursor: pointer;
`