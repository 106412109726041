import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { logout } from '../../store/authSlice';

const LogoutPage = () => {
    const { isLogged } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged) {
            dispatch(logout());  // Dispatch logout action
        }
        navigate('/');  // Navigate to home page
    }, [dispatch, isLogged, navigate]);

    return null;  // This component does not render anything
};

export default LogoutPage;
