import React, { useState, useEffect } from 'react';
import { ResendButton, TimerMainContainer } from './Styles';
import { FormattedMessage } from 'react-intl';

// Define a type for the component props
interface ConfirmationTimerProps {
  initialTime?: number;
  onResend: () => void;
}

const ConfirmationTimer: React.FC<ConfirmationTimerProps> = ({ initialTime = 120, onResend }) => {
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const [isTimeElapsed, setIsTimeElapsed] = useState<boolean>(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsTimeElapsed(true);
    }
  }, [timeLeft]);

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleResendClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onResend();
    setTimeLeft(initialTime);
    setIsTimeElapsed(false);
  };
  

  return (
    <TimerMainContainer>
      {!isTimeElapsed ? (
        <><FormattedMessage id="Label.TimeLeft" />
        : {formatTime(timeLeft)}</>
      ) : (
        <ResendButton onClick={handleResendClick}><FormattedMessage id="Label.ResendCode" /></ResendButton>
      )}
    </TimerMainContainer>
  );
};

export default ConfirmationTimer;
