import styled from "styled-components";
import bookNowBackground from "../../components/assets/images/booknow_background.jpg"
import menu_button from "../../components/assets/icons/Ham_button.svg"

interface BookNowProps {
  isDisplayed: boolean;
}

export const BookNowContainer = styled.div<BookNowProps>`
  position: fixed;  // Change from absolute to fixed
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 1)), url(${bookNowBackground}) center center / cover no-repeat;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: ${props => props.isDisplayed ? '100%' : '90%'};
  width: 100%;
  top: ${props => props.isDisplayed ? '0' : '5vh'};
  left: 0;
  transform: ${props => props.isDisplayed ? 'translateX(0)' : 'translateX(100%)'};
  overflow-y: hidden;  // Prevents vertical overflow
  font-family: 'Jost', sans-serif;
  transition-property: height, top, transform;
  transition-duration: 0.5s, 0.5s, 0.5s;
  transition-timing-function: ease-out, ease-out, ease-out;
  transition-delay: ${props => props.isDisplayed ? '0.5s, 0.5s, 0s' : '0s, 0s, 0.5s'};
`;

export const MenuContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const MenuButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  padding: 15px;
  justify-content: space-between;
  direction:ltr;
`

export const MenuButtonStyle = styled.img.attrs({ src: menu_button })`
  z-index: 1;
`

export const TopScreenStyle = styled.div`
  position: relative;
  top: -45px;
  height: 50%;
`

export const DownScreenStyle = styled.div`
  width: 100%;
`

export const ButtonsContainerStyle = styled.div`
  position: absolute;
  bottom: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

export const ScrollWindowContainerStyle = styled.div`
  position: absolute;
  z-index: 1;
  background-color: aliceblue;
  overflow-y: auto;
  width: 100%;
  height: 0;
  bottom: 0;
  transition: height 1s ease-in-out;
  height: ${props => props.className === "moving" ? '80%' : '0'};
  background: linear-gradient(180deg, #FF4F2D 0%, rgba(255, 255, 255, 0.463542) 53.65%, #E5C4B7 100%);
  border-radius: 33px 33px 0px 0px;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const CloseScrollButtonStyle = styled.div`
  display: flex;
  position: sticky;
  top: 10px;
  justify-content: center;
  margin: 10px 0;
  width: 100%;
  z-index: 2;
`

export const CloseButtonStyle = styled.img`

`

export const MonthTitleStyle = styled.div`
  font-family: 'Jost', sans-serif;  
  font-style: normal;
  margin: 30px 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;
  color: #000000;
`

export const TableStyle = styled.div`
  width: 100%;
`

export const RowTableStyle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const CellTableStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  color: #000000;
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
`

export const DateOptionStyle = styled.div`
  background: #E5C4B7;
  position: relative;
  width: 35px;
  padding: 3px 0;
  margin: 0 1px 4px;
  border-radius: 25px;
  text-align: center;
`

export const DateTimeOptionsConatinerStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 110px);
`

export const DateContainerStyle = styled.div`
  padding: 0 10px;
`

export const TimeOptionsStyle = styled.div`
  display: flex;
  gap: 10px;
  overflow-x: auto;

  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  ::-webkit-scrollbar {
    display: none;
  }
`

export const TimeOptionStyle = styled.div`
  background: #E5C4B7;
  border: 3px solid #304F9C;
  border-radius: 6px;
  padding: 5px;
`

export const TimeContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 10px;
`

export const TimeTitleStyle = styled.div`
  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
`

export const BookNowContainerStyle = styled.div`
  padding: 20px 40px;
`

/*****************Book Now Card*******************/

export const BookNowCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 190px;
  background: #FF4F2D;
  border-radius: 28px;
`

export const SelectedTimeStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const SelectedTimeCellStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;

  /* identical to box height */
  text-align: center;
`

export const SelectedTimeCellTitleStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  color: #D9D9D9;
`

export const SelectedTimeCellValueStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  color: #000000;
`

export const BookNowButtonStyle = styled.div`
  height: 55px;
  margin: 0 60px;
  background: #E5C4B7;
  border: 1px solid #000000;
  border-radius: 41px;

  font-family: 'Jost', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;

  /* identical to box height */
  text-align: center;
`

export const HrStyle = styled.div`
  background-color: #000000;
  width: 1px;
  height: 100%;
`

export const SvgCircle = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg) scale(1, -1);
  width: 36px;
  height: 36px;
`

interface progress {
  percentage: number;
}

export const CircleProgress = styled.circle<progress>`
  animation-name: mat-progress-spinner-stroke-rotate-100;
  stroke-dasharray: ${(prop: { percentage: number; }) => 100 + prop.percentage}px;
  stroke-dashoffset: 100px;
  stroke-width: 4px;
`

