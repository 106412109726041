import { useEffect, useState } from "react";
import WorkingTimesButton from "../../components/workingTimesButton/WorkingTimesButton";
import { RootState } from "../../store/store";
import { ShiftContainer, ShiftDeleteStyle, ShiftTimeStyle, ShiftTitleStyle, TDDayStyle, TDShiftsStyle, THDayStyle, THShiftStyle, TRStyle, TableBodyStyle, TableHeaderStyle, TableStyle, WorkingTimesContainer } from "./Style";
import { useDispatch, useSelector } from "react-redux";
import { getSchedule } from "../../services/BackendServices"
import SignInPopup from "../signInPopup/SignInPopup";
import { FormattedMessage } from "react-intl";
import WorkingDayType from "../../types/rest/WorkingDayType";


const WorkingTimesControl = () => {
    const dispatch = useDispatch();
    const { token, role, isLogged } = useSelector((state: RootState) => state.auth);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [schedule, setSchedule] = useState<WorkingDayType[]>([])

    useEffect(() => {
        if (role === "admin") {
            setIsAdmin(true);
            fetchData();
        }
    }, [role, isLogged]);

    const fetchData = async () => {
        const schedule = await getSchedule();
        if (Array.isArray(schedule)) {
            setSchedule(schedule);
        }
        else {
            setSchedule([]);
        }
    }

    const loggedInFunc = () => {
        setIsLoggedIn(false);
    }

    const getWeekRows = () => {

        return schedule.map(day => {

            return <TRStyle>
                <TDDayStyle><FormattedMessage id={day.dayOfWeek} /></TDDayStyle>
                <TDShiftsStyle>
                    {day.shifts.map(shift => {
                        return (
                            <ShiftContainer>
                                <ShiftTitleStyle>{shift.shiftName}</ShiftTitleStyle>
                                <ShiftTimeStyle>{`${shift.fromHour}-${shift.toHour}`}</ShiftTimeStyle>
                            </ShiftContainer>
                        )
                    })}
                </TDShiftsStyle>
            </TRStyle>
        })
    }

    const onCloseWindow = (refresh:boolean) => {
        if(refresh) {
            fetchData();
        }
    }

    return (
        <>
            {
                (!isAdmin && <SignInPopup closeWindow={loggedInFunc} />) ||
                <WorkingTimesContainer>
                    <TableStyle>
                        <TableHeaderStyle>
                            <TRStyle>
                                <THDayStyle>
                                    <FormattedMessage id="dom" />
                                </THDayStyle>
                                <THShiftStyle>
                                    <FormattedMessage id="shifts" />
                                </THShiftStyle>
                            </TRStyle>
                        </TableHeaderStyle>
                        <TableBodyStyle>
                            {getWeekRows()}
                        </TableBodyStyle>
                    </TableStyle>
                </WorkingTimesContainer>
            }
            <WorkingTimesButton onClose={onCloseWindow} />
        </>
    )
}

export default WorkingTimesControl;