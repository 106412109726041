import styled from 'styled-components';
import { Divider } from '@mui/material';
import background from "../../components/assets/images/Background Lines.svg"
import { Link } from 'react-router-dom';
import InstagramIcon from "@mui/icons-material/Instagram"

interface ButtonProp {
    clicked: boolean;
}

export const MenuMainContainer = styled.div`
    /* position: absolute; */
    background-color: #131315;
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.8)), url(${background}) center center / cover no-repeat;
    opacity: 95%;
    width: 100%;
    height: 100%;
`;

export const MenuHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 150px;
    direction: ltr;
`

export const MenuListDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-left:auto;
    margin-right: 20%;
    width: 100%;
    padding: 0.5em 0;
    gap: 1em;
    overflow-y: scroll;
    position: relative;
    height: calc(100% - 493px);
    /* //!hieght should be calced  */
`;

export const OnClickButton = styled.div<ButtonProp>`
/* //! this is the one should be labeled as a on click button */
    width: 80%;
    padding-left: em;
    border-radius: 0 20px 20px 0;
    display: flex;
    background: ${ props => props.clicked ? "rgba(197, 164, 150, 0.33)": ""};

`;

export const OneMenuButtonContainer = styled(Link)`
    display: flex;
    justify-content: center;
    text-decoration: none;
    flex-direction: row;
    padding: 0.5em;
    justify-content: flex-start;
    gap: 1em;
`;

export const MenuButton = styled.button`
    background-color: transparent;
    border: none;
    color: white;
    font-family: 'Jost', sans-serif;
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Raleway', sans-serif;
    font-size: x-large;
    
`;

export const ButtonLogo = styled.img`
    width: 42px;
    height: 37px;
    justify-content: center;
    display: flex;
    color: #FF4F2D;
    stroke-width: 2;
    fill: none;
    padding-left: 3em;
`;

export const MenuLeftLogo = styled.img`
    position: relative;
    top:10px;
    left: 10px;
    height: 15vh;
    width: 25vw;
`;

export const HamburgerXLogo = styled.img`
    position: relative;
    top:25px;
    right: 25px;
    height: 23px;
    width: 30px;
`;

export const DividerContainer = styled.div`
    width: 100%;
    margin: 2em;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
`;

export const MenuDivider = styled(Divider)`
    height: 1px;
    color: white;
    width: 70%;
    background-color: #c5a496;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left:auto;
    margin-right: auto;

`;

export const OtherSettingDiv = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-self: center;
    border-radius: 10px;
    background: #545252;
    font-family: 'Jost', sans-serif;
    font-size: x-large;
    width:70%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
`;

export const OtherSettingTemmplate = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding:0 10% ;
    gap: 15%;
`;

export const OtherSettingImage = styled.img`
    height: 40px;
    width: 40px;
`;

export const OtherSettingtext = styled.p`
    color: #FFF;
    text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.25);
    font-family: 'Jost', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const SocialMediaDiv = styled.div`
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap:35px;
    margin-left:auto;
    margin-right: auto;
    padding:15px;
    width: 30%;
    margin-bottom:5px;
`;

export const SocialImage = styled.img`
    height: 40px;
    width: 40px;
`;

export const SocialInstaGramHandler = styled(InstagramIcon)`
    height: 40px;
    width: 40px;
    color:#FFFFFF;
    &:first-child{
        height: 40px;
    width: 40px;
    color:#FFFFFF;
    }
`;

export const BackgroundLinesImage = styled.img`
    bottom: 0px;
    position: fixed;
    z-index: -1;
    width: 100%;
`;
