import React, { useState } from "react"
import { TabBarContainer, TabButton } from "./Footer.Style"

import { ReactComponent as AppointmentsIcon } from "../../assets/icons/AppointmentsAdmin.svg"
import { ReactComponent as ShiftsIcon } from "../../assets/icons/Shifts.svg"
import { ReactComponent as HistoryIcon } from "../../assets/icons/HistoryAdmin.svg"
import { ReactComponent as ContactsIcon } from "../../assets/icons/ContactsAdmin.svg"
import { ReactComponent as AppointmentsIconActive } from "../../assets/icons/AppointmentsAdminActive.svg"
import { ReactComponent as ShiftsIconActive } from "../../assets/icons/ShiftsAdminActive.svg"
import { ReactComponent as HistoryIconActive } from "../../assets/icons/HistoryAdminActive.svg"
import { ReactComponent as ContactsIconActive } from "../../assets/icons/ContactsAdminActive.svg"
import { FormattedMessage } from "react-intl"

const TabBar: React.FC = () => {
  const [activeTab, setActiveTab] = useState("appointments")

  return (
    <TabBarContainer>
      <TabButton
        active={activeTab === "appointments"}
        onClick={() => setActiveTab("appointments")}
        to="/control"
      >
        {activeTab === "appointments" ? (
          <AppointmentsIconActive />
        ) : (
          <AppointmentsIcon />
        )}
        <FormattedMessage id={"Appointments"} />
      </TabButton>
      <TabButton
        active={activeTab === "workingTimes"}
        onClick={() => setActiveTab("workingTimes")}
        to="/control/workingTimes"
      >
        {activeTab === "workingTimes" ? <ShiftsIconActive /> : <ShiftsIcon />}
        <FormattedMessage id={"Shifts"} />
      </TabButton>
      <TabButton
        active={activeTab === "history"}
        onClick={() => setActiveTab("history")}
        to="/control/history"
      >
        {activeTab === "history" ? <HistoryIconActive /> : <HistoryIcon />}
        <FormattedMessage id={"History"} />
      </TabButton>
      <TabButton
        active={activeTab === "contacts"}
        onClick={() => setActiveTab("contacts")}
        to="/control/contacts"
      >
        {activeTab === "contacts" ? <ContactsIconActive /> : <ContactsIcon />}
        <FormattedMessage id={"Contacts"} />
      </TabButton>
    </TabBarContainer>
  )
}

export default TabBar
