import { FormattedMessage } from "react-intl";
import IButtonProps from "./IButtonProps";
import { ButtonStyle, ButtonTextStyle } from "./Styles";

const GoldButton: React.FC<IButtonProps> = ({ text, backgroundColor = "Gold", onClick }) => {
    return (
        <ButtonStyle backgroundColor={backgroundColor} onClick={onClick}>
            <ButtonTextStyle>
                <FormattedMessage id={text} />
            </ButtonTextStyle>
        </ButtonStyle>
    )
}

export default GoldButton;