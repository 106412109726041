import { ChangeEvent, useState } from "react";
import IBoxProps from "./IBoxProps";
import { BoxContainer, BoxMessageContainer, BoxMessageStyle, BoxTitleStyle, ContainerBackgroundStyle, OptionsBoxContainer, ConfirmBoxStyle, BoxCloseBoxStyle } from "./Style";
import { FormattedMessage } from "react-intl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const BoxWithSender: React.FC<IBoxProps> = ({ title, message, confirm, close }) => {
    const [messageValue, setMessageValue] = useState<string>(message);
    const [lockButton, setLockButton] = useState(false);
    const [includeMessage, setIncludeMessage] = useState(true);

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const handleMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setMessageValue(event.target.value);
    }

    const confirmFunc = (message: string) => {
        setLockButton(true);
        confirm(message);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIncludeMessage(event.target.checked);
    };

    return (
        <>
            <ContainerBackgroundStyle />
            <BoxContainer>
                <BoxTitleStyle>
                    <FormattedMessage id={title} />
                </BoxTitleStyle>
                <FormControlLabel
                    value="Include Message"
                    control={<Switch {...label} defaultChecked
                        onChange={handleChange}
                    />}
                    label="Include Message"
                    labelPlacement="end"
                />
                <BoxMessageContainer>
                    <BoxMessageStyle
                        id="Message_Box"
                        label="Message"
                        multiline
                        defaultValue={message}
                        onChange={handleMessageChange}
                        rows={4}
                        disabled={!includeMessage}
                    />
                </BoxMessageContainer>
                <OptionsBoxContainer>
                    <ConfirmBoxStyle onClick={() => confirmFunc(includeMessage?messageValue:"")}>
                        <FormattedMessage id="confirm" />
                    </ConfirmBoxStyle>
                    <BoxCloseBoxStyle onClick={() => !lockButton && close()}>
                        <FormattedMessage id="close" />
                    </BoxCloseBoxStyle>
                </OptionsBoxContainer>
            </BoxContainer>
        </>
    )
}

export default BoxWithSender;