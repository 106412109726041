import { FormattedMessage, useIntl } from "react-intl";
import { ConfirmButtonStyle, ErrorMessageStyle, InnerContainerStyle, InputTextStyle, LoginButtonStyle, PopupWindowSubTitleStyle, PopupWindowTitleStyle } from "./Styles";
import { useState } from "react";
import { forgetPassword, resetPassword } from "../../services/BackendServices";

interface IProps {
    onClose: () => void
}

const ForgetPasswordForm: React.FC<IProps> = ({ onClose }) => {
    const { formatMessage } = useIntl();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmCode, setConfirmCode] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [error, setError] = useState<String | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const [isConfirmForm, setIsConfirmForm] = useState(false)
    const [isChangePassword, setIsChangePassword] = useState(false)

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value)
    }

    const handleConfirmCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmCode(e.target.value)
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const handlePasswordConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirmation(e.target.value)
    }

    const onVerifyOTP = async () => {
        setIsLoading(true);
        const response = await resetPassword(phoneNumber, confirmCode, password, passwordConfirmation);
        if ("message" in response) {
            setError(response.message)
        }
        else {
            onSwitchToBack();
        }
        setIsLoading(false);
    }

    const onConfirm = async () => {
        setIsLoading(true);
        const response = await forgetPassword(phoneNumber);
        if ("message" in response) {
            setError(response.message)
        }
        else {
            setIsConfirmForm(true)
        }
        setIsLoading(false);
    }

    const onSwitchToBack = () => {
        setIsLoading(true);
        setIsConfirmForm(false);
        onClose();
        setIsLoading(false);
    }

    if (!isConfirmForm) {
        return (<>
            <InnerContainerStyle>
                <PopupWindowTitleStyle>
                    <FormattedMessage id="Label.forgetPassword" />
                </PopupWindowTitleStyle>
                <PopupWindowSubTitleStyle>
                    <FormattedMessage id="Label.forgetPassword.SubLabel" />
                </PopupWindowSubTitleStyle>
            </InnerContainerStyle>
            <InputTextStyle
                id="PhoneNumber"
                label={formatMessage({ id: "Label.PhoneNumber" })}
                type="text"
                autoComplete="current-PhoneNumber"
                variant="standard"
                value={phoneNumber}
                onChange={handlePhoneNumberChange} />
            <InnerContainerStyle>
                {error && <ErrorMessageStyle>{error}</ErrorMessageStyle>}
                <ConfirmButtonStyle
                    onClick={onConfirm}
                    value={formatMessage({
                        id: isLoading ? "Btn.Loading" : "Btn.Confirm"
                    })}
                    disabled={isLoading}
                />
            </InnerContainerStyle>
            <InnerContainerStyle>
                {error && <ErrorMessageStyle>{error}</ErrorMessageStyle>}
                <ConfirmButtonStyle
                    onClick={onSwitchToBack}
                    value={formatMessage({
                        id: "Btn.Back"
                    })}
                    disabled={isLoading}
                />
            </InnerContainerStyle>
        </>)
    }

    return (<>
        <InnerContainerStyle>
            <PopupWindowTitleStyle>
                <FormattedMessage id="Label.forgetPassword" />
            </PopupWindowTitleStyle>
            <PopupWindowSubTitleStyle>
                <FormattedMessage id="Label.forgetPassword.SubLabel" />
            </PopupWindowSubTitleStyle>
        </InnerContainerStyle>
        <InputTextStyle
            id="ConfirmCode"
            label={formatMessage({ id: "Label.ConfirmCode" })}
            type="text"
            autoComplete="current-ConfirmCode"
            variant="standard"
            value={confirmCode}
            onChange={handleConfirmCodeChange} />
        <InputTextStyle
            id="Password"
            label={formatMessage({ id: "Label.Password" })}
            type="text"
            autoComplete="current-Password"
            variant="standard"
            value={password}
            onChange={handlePassword} />
        <InputTextStyle
            id="PasswordConfirmation"
            label={formatMessage({ id: "Label.PasswordConfirmation" })}
            type="text"
            autoComplete="current-PasswordConfirmation"
            variant="standard"
            value={passwordConfirmation}
            onChange={handlePasswordConfirmation} />
        <InnerContainerStyle>
            {error && <ErrorMessageStyle>{error}</ErrorMessageStyle>}
            <ConfirmButtonStyle
                onClick={onVerifyOTP}
                value={formatMessage({
                    id: isLoading ? "Btn.Loading" : "Btn.Confirm"
                })}
                disabled={isLoading}
            />
        </InnerContainerStyle>
        <InnerContainerStyle>
            {error && <ErrorMessageStyle>{error}</ErrorMessageStyle>}
            <ConfirmButtonStyle
                onClick={onSwitchToBack}
                value={formatMessage({
                    id: "Btn.Back"
                })}
                disabled={isLoading}
            />
        </InnerContainerStyle>
    </>)
}

export default ForgetPasswordForm;