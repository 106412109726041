import { useRef } from "react";
import { TimeContainer, TimesListContainer } from "./Style";
import IShiftsListProps from "./IShiftsListProps";

const ShiftsList: React.FC<IShiftsListProps> = ({shifts, onClick, selected}) => {
    
    const containerRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const initialX = useRef(0);
    const scrollX = useRef(0);

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        isDragging.current = true;
        initialX.current = e.clientX;
        scrollX.current = containerRef.current?.scrollLeft || 0;
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDragging.current && containerRef.current) {
            containerRef.current.scrollLeft = scrollX.current - e.clientX + initialX.current;
        }
    };

    const onMouseUp = () => {
        isDragging.current = false;
    };
    
    return (
        <TimesListContainer
            ref={containerRef}
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseUp}
        >
            {shifts && shifts.map(time => {
                const isSelected = time===selected;
                return <TimeContainer selected={isSelected} onClick={() => onClick(time)}>{time}</TimeContainer>
            })}
        </TimesListContainer>
    )
}

export default ShiftsList;