import { useState } from "react";
import { BackgroundContainer, BookingButtonContainer, BookingButtonStyle, ButtonContainer, ButtonOptionContainer, ButtonOptionStyle, OptionLabelStyle } from "./Style";
import BookingOptionsIcon from "../assets/icons/BookingOptionsIcon.svg"
import Shift from "../assets/icons/shift.svg";
import SpecialDay from "../assets/icons/add_favorite.svg";
import ChangeSchedule from "../assets/icons/change_schedule.svg";
import { FormattedMessage } from "react-intl";
import ShiftsControllerBox from "../shiftsControllerBox/ShiftsControllerBox";
import WorkingScheduleBox from "../workingScheduleBox/WorkingScheduleBox";
import IProps from "./IProps";
import SpecialDayController from "../specialDayController/SpecialDayController";

const WorkingTimesButton: React.FC<IProps> = ({onClose}) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)
    const [showOptions, setShowOptions] = useState(false);
    const options = ['change_working_schedule', 'add_Shift', 'add_special_day'];

    const getIcon = (option: string) => {
        switch (option) {
            case "change_working_schedule": return ChangeSchedule;
            case "add_Shift": return Shift;
            case "add_special_day": return SpecialDay;
        }
    }

    const getLabel = (option: string) => {
        switch (option) {
            case "change_working_schedule": return <FormattedMessage id="change_working_schedule" />;
            case "add_Shift": return <FormattedMessage id="add_shift" />;
            case "add_special_day": return <FormattedMessage id="add_special_day" />;
        }
    }

    const getOptionBox = () => {
        switch (selectedOption) {
            case "change_working_schedule": return <WorkingScheduleBox onClose={closeWindow} />;
            case "add_Shift": return <ShiftsControllerBox />;
            case "add_special_day": return <SpecialDayController />;
        }
    }

    const switchOptionsState = () => {
        setSelectedOption(undefined);
        setShowOptions(!showOptions);
    }

    const closeWindow = (refresh:boolean) => {
        switchOptionsState();
        onClose(refresh);
    }

    return (
        <>
            {showOptions && <BackgroundContainer onClick={() => switchOptionsState()} />}
            {selectedOption && getOptionBox()}
            <ButtonContainer>
                <BookingButtonContainer onClick={() => switchOptionsState()}>
                    <BookingButtonStyle src={BookingOptionsIcon} />
                </BookingButtonContainer>
                {showOptions && options.map((option, index) => (
                    <ButtonOptionContainer key={option} index={index}
                        onClick={() => setSelectedOption(option)}>
                        <ButtonOptionStyle src={getIcon(option)} />
                        <OptionLabelStyle>{getLabel(option)}</OptionLabelStyle>
                    </ButtonOptionContainer>
                ))}
            </ButtonContainer>
        </>
    )
}

export default WorkingTimesButton;