import { FormattedMessage } from "react-intl";
import IBoxProps from "./IBoxProps";
import { BoxBodyStyle, BoxCloseBoxContainer, BoxCloseBoxStyle, BoxContainer, BoxTitleStyle, ContainerBackgroundStyle, UserKeyStyle, UserParamBoxStyle, UserValueStyle } from "./Style";
import { useEffect } from "react";

const UserInformationBox: React.FC<IBoxProps> = ({ user, close }) => {
    return (
        <>
            <ContainerBackgroundStyle />
            <BoxContainer>
                <BoxTitleStyle>
                    <FormattedMessage id="user_information" />
                </BoxTitleStyle>
                <BoxBodyStyle>
                    <UserParamBoxStyle>
                        <UserKeyStyle>
                            <FormattedMessage id="full_name" />
                        </UserKeyStyle>
                        <UserValueStyle>{user.fullName}</UserValueStyle>
                    </UserParamBoxStyle>
                    <UserParamBoxStyle>
                        <UserKeyStyle>
                            <FormattedMessage id="phone_number" />
                        </UserKeyStyle>
                        <UserValueStyle>+{user.phoneNumber}</UserValueStyle>
                    </UserParamBoxStyle>
                </BoxBodyStyle>
                <BoxCloseBoxContainer>
                    <BoxCloseBoxStyle onClick={close}>
                        <FormattedMessage id="close" />
                    </BoxCloseBoxStyle>
                </BoxCloseBoxContainer>
            </BoxContainer>
        </>
    )
}

export default UserInformationBox;