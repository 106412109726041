import styled from "styled-components";

interface IButtonProps {
    backgroundColor: String;
}

export const ButtonStyle = styled.div<IButtonProps>`
    display: flex;
    height: 66px;
    background: ${props => props.backgroundColor === "Gold" ? "#c5a496" : "" };
    border: 1px solid #c5a496; 
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 17px; 
    max-width: 500px;
    width: 80%;
    font-family: 'Jost', sans-serif;
`

export const ButtonTextStyle = styled.div`
    margin: auto;
    text-align: center;

    color: #FFF;
    text-align: center;
    text-shadow: 3px 5px 5px rgba(0, 0, 0, 0.25);
    font-family: 'Jost', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
`