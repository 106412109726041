import styled from "styled-components";

export const SliderContainerStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 0;
    gap: 20px;
    direction: ltr;
`

export const SliderHeaderStyle = styled.div`
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: 'Jost', sans-serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    width: 100%;
    
`

export const SliderStyle = styled.div`
    gap: 10px;
    overflow-x: scroll;
    white-space: nowrap;
    padding-left: 10px;

    /* Scrollbar styling for WebKit-based browsers */
    &::-webkit-scrollbar {
        height: 12px; /* Adjust the height of the scrollbar */
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc; /* Color of the dots */
        border-radius: 6px;
        background-clip: content-box;
        border: 4px solid transparent; /* Adjust the size of the dots */
    }

    /* Scrollbar styling for Firefox */
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;

    &::-moz-scrollbar-thumb {
        border-radius: 6px;
    }
`

export const PhotoStyle = styled.img`

    max-width: 100%;
    height: 260px;
    width: 330px;
    /* margin-left: 16px; */
    margin-right: 10px;
    background-color: aqua;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid black;
`

export const DotsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
`;

export const Dot = styled.div`
    width: 8px;
    height: 8px;
    background-color: #D9D9D9;
    border-radius: 50%;
    margin: 0 2px;
    &.active {
        background-color: #FF4F2D;
    }
`;