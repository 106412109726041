// src/TimePicker.tsx
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface TimeSlot {
  time: string;
  slots: string; // A string representation of slots, e.g., 'XXV' where X is taken and V is available
  isFree: boolean;
}

const TimePickerWrapper = styled.div`
  font-family: 'Jost', sans-serif;
  background-color: rgba(28, 28, 28,0.8);
  padding: 20px;
  border-radius: 10px;
  /* width: auto; */
  height: auto;
  /* overflow-y: scroll; */
  margin: auto 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const TimeSlotItem = styled.div<{ selected: boolean }>`
  display: flex;
  /* width: 18%; */
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px auto;
  background-color: ${props => (props.selected ? '#8b6a61' :'#fff' )};
  border-radius: 5px;
  
`;

const SlotIndicator = styled.div<{ isFree: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => (props.isFree ? '#4caf50' : '#f44336')};
  margin-left: 2px;
`;

const SlotContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Timeheader = styled.div`
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  margin: 10px 5px;
  width: 100%;
  text-align: center;
  justify-content: center;
`;





const TimePicker: React.FC<{ setTime: (time: string) => void,times:string[] }> = ({ setTime ,times}) => {
  const [timeSlots,setTimesSlots] = useState<string[]>(times);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [loading , setLoading] = useState<boolean>(true)

  const handleTimeClick = (time: string) => {
    setSelectedTime(time);
    setTime(time);
  };

  useEffect(() => {

    setLoading(true);
    setTimesSlots(times)
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  } 
  ,[times])

  return (
    <TimePickerWrapper>
      {loading && <Timeheader><FormattedMessage id="loading" /></Timeheader>}
      {!loading && timeSlots.length === 0 && <Timeheader>No available time slots</Timeheader>}
      {!loading && timeSlots.length > 0 && timeSlots.map((slot, index) => (
        <TimeSlotItem
          key={index}
          
          selected={selectedTime === slot}
          onClick={() => handleTimeClick(slot)}
        >
          {slot}
          {/* <SlotContainer>
            {slot.slots.split('').map((s, i) => (
              <SlotIndicator key={i} isFree={s === 'V'} />
            ))}
          </SlotContainer> */}
        </TimeSlotItem>
      ))}
    </TimePickerWrapper>
  );
};

export default TimePicker;
