import styled from 'styled-components';

export const HomeMainDiv = styled.div`
    background: linear-gradient(180deg, rgba(38, 39, 50, 0.85) 0%, rgba(78, 67, 63, 0.95) 15%, rgba(150, 92, 70, 0.90) 50%, rgba(195, 154, 136, 0.85) 75%, rgba(225, 205, 188, 0.80) 100%);
    flex-direction: column;
`;

export const MainHomeContainerFixed = styled.div`
    position: relative;
    width: 100%;
`;

export const MainHomeContainer = styled.div`
    position: sticky;
    top: 0;

`;


