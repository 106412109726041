import styled from "styled-components";
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import calender_icon from "../../components/assets/icons/calender_icon.svg";

export const ScrollWindowContainerStyle = styled.div`
  direction: ltr;
  position: fixed;
  z-index: 1;
  background-color: aliceblue;
  font-family: 'Jost', sans-serif;
  overflow-y: auto;
  width: 100%;
  bottom: 0;
  height: 100%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transform: translateX(-50%);
  max-width: 450px;
  transition: height 0.5s ease-in-out;
  height: ${props => props.className === "moving" ? '100%' : '0'};
  /* border-radius: 50px 50px 0px 0px; */
  background: #292929;  
  ::-webkit-scrollbar {
    display: none;
  }
`

export const CloseScrollButtonStyle = styled.div`
  display: flex;
  position: sticky;
  top: 10px;
  justify-content: center;
  margin: 10px 0 0;
  width: 100%;
  z-index: 2;
`

export const CloseButtonStyle = styled.img`

`

export const ScrollWindowBackgroundStyle = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
`
/*****************Book Now Card*******************/

export const BookNowContainerStyle = styled.div`
border-radius: 40px 40px 0px 0px;
background: #E8E8E8;
height: auto;
max-height :11em;
position: auto;
bottom: 0;
  overflow-y: none;

`
// ! CHOOSE SEREVICE
export const Container = styled.div`
  background-color: rgba(28, 28, 28,0.8);
  padding:10px 20px;
  border-radius: 14px;
  /* max-width: 100%; */
  margin: 0 10px;
  color: #fff;
`;

export const TimeContainer = styled.div`
  background-color: rgba(28, 28, 28,0.8);
  padding:10px 20px;
  border-radius: 14px;
  /* max-width: 100%; */
  margin: 0 10px;
  color: #fff;
`;


export const ContainerDays = styled.div`
  background-color: rgba(28, 28, 28,0.8);
  padding:10px 20px;
  border-radius: 14px;
  /* max-width: 100%; */
  margin: 0 10px;
  color: #fff;
  flex-direction: row;
`;

export const Title = styled.h2`
  text-align: flex-start;
  font-size: 20px;
  font-weight: 400;
  margin: 20px 20px;
  font-family: 'Jost', sans-serif;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const ServiceList = styled.div`
  margin: 20px 0;
`;

interface ServiceItemProps {
  selected?: boolean;
}

export const ServiceItem = styled.div<ServiceItemProps>`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  color: black;
  /* background-color: #FFF; */
  font-size: 20px;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  border-radius: 10px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? '#8b6a61' : '#FFF')};
  font-family: 'Jost', sans-serif;

  &:hover {
    background-color: #8b6a61;
  }
`;

export const ServiceIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 15px;
`;

export const ServiceName = styled.span`
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: 'Jost', sans-serif;
`;

export const DataSipDiv = styled.div` 
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Jost', sans-serif;

`;

export const BookNowButtonContainer = styled.div`
  position: sticky;
  /* margin-bottom: 0; */
  margin-top: auto;
  width: 90%;  
  /* max-width: 400px; */
  bottom: 0;
  display: flex;
  justify-content: center;  
  padding: 10px 20px;
  border-radius: 30px 30px 0 0;
  background-color: #1c1c1c;
  z-index: 1; 
  margin-left: auto;
  margin-right: auto;
  
  margin-top: 20px;

  /* margin: 0 auto; */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
`;

export const BookButton = styled.button`
  background-color: #c5a496;
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 15px 20px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Jost', sans-serif;
  &:hover {
    background-color: #c5b496;
  }
`;