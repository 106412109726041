import { useDispatch, useSelector } from "react-redux"
import {
  getSelectedDate,
  reset,
  selectDate
} from "../../../store/dateSelectionSlice"
import { useEffect, useState } from "react"
import {
  ButtonStyle,
  HeaderContainer,
  TodayButtonStyle,
  ArrowButton
} from "./Styles"
import { getDates } from "../../../store/appointmentsSlice"
import { FormattedMessage } from "react-intl"
import moment from "moment-timezone"

const WeekHeader = () => {
  const selectedDay = useSelector(getSelectedDate)
  // const days = useSelector(getDates);
  const dispatch = useDispatch()

  const [currentDate, setCurrentDate] = useState<string>()
  const [firstDayPointer, setFirstDayPointer] = useState<Date>()

  useEffect(() => {
    const currentDate: Date = moment.tz("Asia/Jerusalem").toDate()
    setFirstDayPointer(currentDate)
    setCurrentDate(formatDate(currentDate))
    dispatch(selectDate(formatDate(currentDate)))
  }, [dispatch])

  const selectDay = (day: string) => {
    dispatch(selectDate(day))
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    

    // Pad the month and day with a leading zero if they are less than 10
    const formattedMonth = month < 10 ? `0${month}` : month
    const formattedDay = day < 10 ? `0${day}` : day

    return `${year}-${formattedMonth}-${formattedDay}`
  }

  const FullWeekHeader = () => {
    const dates: Date[] = []
    const datePointer = firstDayPointer || new Date()

    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(datePointer)
      nextDate.setDate(datePointer.getDate() + i)
      dates.push(nextDate)
    }

    const getNextWeek = () => {
      const newDatePointer = new Date(datePointer);
      const date = formatDate(newDatePointer)
      newDatePointer.setDate(datePointer.getDate() + 7);
      setFirstDayPointer(newDatePointer);
      // selectDay(date)
    };
    
    const getPreviousWeek = () => {
      const newDatePointer = new Date(datePointer);
      const date = formatDate(newDatePointer)
      newDatePointer.setDate(datePointer.getDate() - 7);
      setFirstDayPointer(newDatePointer);
      // selectDay(date)
    };

    return (
      <HeaderContainer>
        <ArrowButton onClick={getPreviousWeek}>
          <FormattedMessage id="<"   />
        </ArrowButton>
        {dates.map((d) => {
          const day = d.getDate().toString()
          const date = formatDate(d)

          if (currentDate === date) {
            return (
              <TodayButtonStyle
                clicked={date === selectedDay}
                onClick={() => selectDay(date)}
              >
                <FormattedMessage id="today" />
              </TodayButtonStyle>
            )
          }

          return (
            <ButtonStyle
              clicked={date === selectedDay}
              onClick={() => selectDay(date)}
            >
              {day}
            </ButtonStyle>
          )
        })}
        <ArrowButton onClick={getNextWeek}>
          <FormattedMessage id=">" />
        </ArrowButton>
      </HeaderContainer>
    )
  }

  return <FullWeekHeader />
}

export default WeekHeader
