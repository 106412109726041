import { useEffect, useRef, useState } from "react";
import { StatusList, BottomClientTabDiv, ButtonStyle, ButtonsContainerStyle, ContactsPageContainer, NameStyle, RowStyle, StyledDeleteIcon, StyledVerifyIcon, StyledBlockIcon, StyledWhatsappIcon, PhoneNumberContains, TopClientTabDiv, StatusIcon, LoadContainer, LoadButtonStyle } from "./Styles";
import { UserData } from "../../types/rest/ContactsResponse"
import { getContacts, changeBlockStatsForUser, changeVerifyStatsForUser, deleteUserAccount } from "../../services/BackendServices";
import verifyIcon from "../../components/assets/icons/verified.png"
import blockedUserIcon from "../../components/assets/icons/block-user.png"

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useIntl } from "react-intl";

const ContactsPage = () => {
    const { formatMessage } = useIntl()

    const [usersData, setUsersData] = useState<UserData[]>([]);
    const [nextIndex, setNextIndex] = useState<number>(0);
    const [nextIndexValue, setNextIndexValue] = useState<number | null>(null);
    const [totalCount, setTotalCount] = useState<number>(0);

    const loading = useRef<boolean>(true);

    const [boxType, setBoxType] = useState<string | null>(null);
    const [userSelected, setUserSelected] = useState<UserData | null>(null);

    useEffect(() => {
        fetchData(nextIndex, 10)
    }, [nextIndex])

    const fetchData = async (fromIndex: number, range: number, search: string | null = null) => {
        loading.current = true;
        const contactsData = await getContacts(fromIndex, range, search);
        if (!("message" in contactsData)) {
            setUsersData([...usersData, ...contactsData.users]);
            setTotalCount(contactsData.total_count);
            setNextIndexValue(contactsData.next_index);
        }
        else {
            console.log(contactsData);
        }
        loading.current = false;
    }

    const loadMore = () => {
        if (loading.current) return;
        if (nextIndexValue) setNextIndex(nextIndexValue)
    }

    const openConfirmBox = (boxType: string, user: UserData) => {
        setBoxType(boxType);
        setUserSelected(user);
    }

    const boxConfirmFunc = () => {
        if (userSelected === null) return;
        switch (boxType) {
            case "blockUserConfirmation":
                changeBlockStatus(userSelected);
                break;
            case "VerifyUserConfirmation":
                changeConfirmStatus(userSelected);
                break;
            case "DeleteUserConfirmation":
                deleteUser(userSelected);
                break;
        }
        boxCanceledFunc();
    }

    const boxCanceledFunc = () => {
        setBoxType(null);
        setUserSelected(null);
    }

    const boxMessage = () => {
        if (userSelected === null || boxType === null) return;
        return formatMessage(
            { id: `messageBox.${boxType}` },
            { full_name: userSelected.fullName }
        )
    }

    const changeBlockStatus = async (user: UserData) => {
        const status: boolean = !user.blocked;
        const response = await changeBlockStatsForUser(user.phoneNumber, status)
        if (!("message" in response)) {
            setUsersData(usersData.map(user => {
                if (user.phoneNumber === response.phoneNumber) {
                    return { ...user, blocked: response.status };
                }
                return user;
            }));
        } else {
            console.log(response.message);
        }
    }

    const changeConfirmStatus = async (user: UserData) => {
        const status: boolean = !user.confirmed;
        const response = await changeVerifyStatsForUser(user.phoneNumber, status)
        if (!("message" in response)) {
            setUsersData(usersData.map(user => {
                if (user.phoneNumber === response.phoneNumber) {
                    return { ...user, confirmed: response.status };
                }
                return user;
            }));
        } else {
            console.log(response.message);
        }
    }

    const deleteUser = async (user: UserData) => {
        const response = await deleteUserAccount(user.phoneNumber)
        if (!("message" in response)) {
            if (response.was_deleted) {
                const newUsersData = usersData.filter(user => user.phoneNumber !== response.phoneNumber);
                setUsersData(newUsersData);
            }
        } else {
            console.log(response.message);
        }
    }

    return (
        <ContactsPageContainer>
            {<Dialog
                open={boxType !== null}
                onClose={boxCanceledFunc}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {boxMessage()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={boxCanceledFunc}>Cancel</Button>
                    <Button onClick={boxConfirmFunc}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            }
            {usersData.map(user =>
                <RowStyle key={user.phoneNumber}>
                    <TopClientTabDiv>
                        <NameStyle>{user.fullName}</NameStyle>
                        <StatusList>
                            {user.confirmed && <StatusIcon src={verifyIcon} />}
                            {user.blocked && <StatusIcon src={blockedUserIcon} />}
                        </StatusList>
                    </TopClientTabDiv>
                    <BottomClientTabDiv>
                        <PhoneNumberContains>
                            <ButtonStyle
                                target="_blank"
                                href={`https://wa.me/${user.phoneNumber.replace(/^0/, "972")}`}
                            >
                                {user.phoneNumber}
                            </ButtonStyle>
                        </PhoneNumberContains>
                        <ButtonsContainerStyle>
                            <ButtonStyle>
                                <StyledVerifyIcon
                                    onClick={() => openConfirmBox("VerifyUserConfirmation", user)}
                                />
                            </ButtonStyle>
                            <ButtonStyle>
                                <StyledBlockIcon onClick={() => openConfirmBox("blockUserConfirmation", user)} />
                            </ButtonStyle>
                            <ButtonStyle>
                                <StyledDeleteIcon onClick={() => openConfirmBox("DeleteUserConfirmation", user)} />
                            </ButtonStyle>
                        </ButtonsContainerStyle>
                    </BottomClientTabDiv>
                </RowStyle>
            )
            }
            <LoadContainer>
                {nextIndexValue && <LoadButtonStyle onClick={loadMore}>Load more (left {totalCount - usersData.length})</LoadButtonStyle>}
            </LoadContainer>
        </ContactsPageContainer >
    )
}

export default ContactsPage;