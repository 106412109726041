import { BrowserRouter, Routes, Route } from "react-router-dom"
import Layout from "../../components/layouts/Layout"
import BookNow from "../bookNow/BookNow"
import Gallery from "../gallery/Gallery"
import Home from "../home/Home"
import "./App.css"
import { Provider, useDispatch } from "react-redux"
import { store } from "../../store/store"
import { useEffect } from "react"
import { isLoggedIn } from "../../services/BackendServices"
import { login, logout } from "../../store/authSlice"
import ControlPage from "../controlPage/ControlPage"
import ControlLayout from "../../components/controlLayout/ControlLayout"
import WorkingTimesControl from "../workingTimesControl/WorkingTimesControl"
import SignInPage from "../signInPage/SignInPage"
import LogoutPage from "../logoutPage/LogoutPage"
import History from "../History/History"
import { useIntl } from "react-intl"
import UserAppointments from "../../components/UserAppointments/UserAppointments"
import BookingButton from "../../components/bookingButton/BookingButton"
import ContactsPage from "../contactsPage/ContactsPage"

function AppRoutes() {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await isLoggedIn()
      if (!loggedIn) {
        dispatch(logout())
      }
    }

    checkLoginStatus()
  }, [dispatch])

  return (
    <Provider store={store}>
      <div
        className={`full-screen ${formatMessage({
          id: "page_direction_control"
        })}`}
      >
        <BrowserRouter>
          <Routes>
            <Route index path="/" element={<BookNow />} />
            <Route path="/login" element={<SignInPage />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/userappointments" element={<UserAppointments/>} />
            <Route path="/home" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="gallery" element={<Gallery />} />
            </Route>
            <Route path="/control" element={<ControlLayout />}>
              <Route index element={<ControlPage />} />
              <Route path="workingTimes" element={<WorkingTimesControl />} />
              <Route path="contacts" element={<ContactsPage />} />
              <Route path="history" element={<History />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  )
}

export default AppRoutes