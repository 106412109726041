import styled from 'styled-components';
import HomeBackgroundImage from "../../components/assets/images/homeBackGround.png"

interface HomePageProps {
    isDisplayed: boolean;
  }

// export const MainLayoutContainer = styled.div`
//     height: 100%;
//     width: 100%;
//     overflow-y: scroll;
    
// `;

export const MainLayoutContainer = styled.div<HomePageProps>`
  position: fixed;  // Change from absolute to fixed
  /* background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.8)), url(${HomeBackgroundImage}) center center / cover no-repeat; */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  height: ${props => props.isDisplayed ? '100%' : '90%'};
  width: 100%;
  top: ${props => props.isDisplayed ? '0' : '5vh'};
  left: 0;
  transform: ${props => props.isDisplayed ? 'translateX(0)' : 'translateX(100%)'};
  overflow-y: scroll;  // Prevents vertical overflow: ;
  overflow-x: hidden;
  background: linear-gradient(152deg, rgba(197, 76, 70, 0.80) -0.08%, rgba(147, 114, 112, 0.40) 125.64%);
  transition-property: height, top, transform;
  transition-duration: 0.5s, 0.5s, 0.5s;
  transition-timing-function: ease-out, ease-out, ease-out;
  transition-delay: ${props => props.isDisplayed ? '0.5s, 0.5s, 0s' : '0s, 0s, 0.5s'};
  background-color: black;
`;

export const MenuContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const LowerLayoutContainer = styled.div`
    background: linear-gradient(180deg, #262732 0.96%, rgba(97, 53, 57, 0.96) 5.76%, rgba(136, 62, 62, 0.94) 10.57%, rgba(197, 76, 70, 0.90) 34.34%, rgba(38, 39, 50, 0.22) 78.66%), linear-gradient(152deg, rgba(197, 76, 70, 0.49) -0.08%, rgba(192, 79, 74, 0.76) 40.52%, rgba(147, 114, 112, 0.40) 125.64%);
    height: 100%;
`;

