import styled from "styled-components";
import TextField from '@mui/material/TextField';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface boxContainerProps {
    boxSize: "big" | "small"
}

export const BoxContainer = styled.div<boxContainerProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: ${props => props.boxSize === "big" ? 90 : 80}%;
    height: ${props => props.boxSize === "big" ? 80 : 70}%;
    z-index: 10;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    transition: all 0.5s ease;
    overflow-y: hidden;
`

export const HeaderStyle = styled.div`
    height: 50px;
`

export const BoxBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #c5a496;
    height: 100%;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #222532;
`

export const BoxTitleStyle = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0 0;
`

export const ChangeStatusBtnStyle = styled.div`
    padding: 10px;
    background-color: #c5a496;
    cursor: pointer;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #222532;
`

export const TableStyle = styled.table`
    width: 100%;

    td, th {
        text-align: center;
    }

    th {
        background-color: #8b6a61;
        color: #222532;
        padding: 10px 0;
    }

    tr:nth-child(even) {
        background-color: #998769;
    }

    tr:nth-child(odd) {
        background-color: #736355;
    }
`;

export const TableHeaderStyle = styled.thead`

`

export const TableBodyStyle = styled.tbody`
  display: block; 
  /* height: 373px; */
  overflow-y: auto;
`;

export const TRStyle = styled.tr`
  display: table; 
  width: 100%; 
  table-layout: fixed;
  overflow-x: hidden;
`;

export const THStyle = styled.th`
    width: 29%;
`

export const THDeleteStyle = styled.th`
    width: 40px;
`

export const TDStyle = styled.td`
    width: 22%;
`

export const TDDeleteStyle = styled.td`
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    /* left: 0; */
    /* width: 12%; */
    
`

export const DeleteButtonStyle = styled.img`
    /* width: 30px; */
    /* height: 30px; */
    margin: 0 30px;
    cursor: pointer;
`

// Add Shift Style

export const AddShiftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 50px;
    gap: 20px;
`

export const ChoiceBoxStyle = styled.div`
    display: flex;
`

export const KeyStyle = styled.div`
    width: 100px;
`

export const ValueStyle = styled.input`
    width: 100%;
`

export const InputTextStyle = styled(TextField)`
    width: 100%;
    color: black;
`

export const LocalizationProviderStyle = styled(LocalizationProvider)``;

export const InputContainerStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
});

export const InputTimeStyle = styled(TimePicker)``;

export const SubmitBtnStyle = styled.input`
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    border-radius: 5px;
`