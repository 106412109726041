import { Provider, useSelector } from "react-redux";
import { RootState, persistor, store } from "./store/store";
import AppRoutes from "./containers/AppRoutes/AppRoutes";
import { IntlProvider } from 'react-intl';
import translations from './locales/translations';

import './App.css';
import { PersistGate } from "redux-persist/integration/react";
import { getSelectedLanguage } from "./store/languagesSlice";
import { SupportedLanguageType } from "./types/rest/SupportedLanguageType";

function LanguageProvider({ children }: { children: React.ReactNode }) {
  const selectedLanguage = useSelector(getSelectedLanguage);
  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={translations[selectedLanguage as SupportedLanguageType]}
    >
      {children}
    </IntlProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LanguageProvider>
          <AppRoutes />
        </LanguageProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;