import styled from "styled-components";

export const LanguagesContainer = styled.div`
    display: flex;
    background-color: rgba(197, 164, 150, 0.9);
    border-radius: 20px 5px 5px 20px;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    gap: 10px;
    padding: 2px;
`;

export const LangIconStyle = styled.div`
    display: flex;

    align-items: center;
    width: 50%;
    height: 100%;
`

export const LangIcon = styled.img`
    /* width: 100%;   */
    height: 90%;
    
`

export const LangValueStyle = styled.div`
    color: #FFF;
    text-align: center;
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const LangArrowStyle = styled.div`
    display: flex;
    width: 20%;
`

export const ArrowIcon = styled.img`
    width: 100%;
`

export const DropdownContainer = styled.div`
    position: relative;
    height: 40px;
    /* left:20px; */
    /* top: 20px; */
    z-index: 1;
    direction: ltr;
`;

export const DropdownMenu = styled.div<{ open: boolean }>`
    display: ${props => props.open ? 'block' : 'none'};
    position: absolute;
    background-color: #f9f9f9;
    min-width: 50px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
`;

export const DropdownItem = styled.div`
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    &:hover {
        background-color: #f1f1f1
    }
    font-family: 'Jost', sans-serif;
`;
