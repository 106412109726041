import styled from "styled-components";

interface ButtonProps {
    clicked: boolean;
}

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    /* line-height: 39px; */
    color: #222532;
    gap:3px;
    
`

export const TodayButtonStyle = styled.div<ButtonProps>`
    display: flex;
    width: calc(4vh);
    height: calc(4vh);
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    max-width: 220px;
    min-width: 100px;
    font-weight:400;
    font-size: 22px;
    background: ${(props) => (props.clicked ? '#8b6a61' : '#c5a496')};
    &:hover {
        background: ${(props) => (props.clicked ? '#8b6a61' : '#c5a496')};
        cursor: pointer;
    }
`

export const ButtonStyle = styled.div<ButtonProps>`
    display: flex;
    width: calc(4vh);
    height: calc(4vh);
    justify-content: center;
    align-items: center;
    font-size: small;
    border-radius: 190px;
    font-weight:400;   
    font-size: 20px;
    background: ${(props) => (props.clicked ? '#8b6a61' : '#c5a496')};
    &:hover {
        background: ${(props) => (props.clicked ? '#8b6a61' : '#c5a496')};
        cursor: pointer;
    }
`

export const ArrowButton =  styled.div`
    display: flex;
    font-size: 20px;
    font-weight: bold;
    border-radius: 50px;
    background-color:#c5a496;
    width: calc(4vh);
    height: calc(4vh);
    align-items: center;
    justify-content: center;
    cursor: pointer;
`