import { useEffect, useState } from 'react';
import { ArrowIcon, LangArrowStyle, LangIcon, LangIconStyle, LangValueStyle, LanguagesContainer, DropdownContainer, DropdownMenu, DropdownItem } from "./Style";
import langIcon from "../assets/icons/internet.svg"
import arrowIcon from "../assets/icons/arrow-down.svg"
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage, getSelectedLanguage } from '../../store/languagesSlice';
import { SupportedLanguageType } from '../../types/rest/SupportedLanguageType';

const LanguagesController = () => {
    const dispatch = useDispatch();
    const selectedLanguage = useSelector(getSelectedLanguage);


    const [open, setOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState<SupportedLanguageType>("He");

    const languages: SupportedLanguageType[] = ['En', 'He', 'Ar'];

    const handleOpen = () => setOpen(!open);

    useEffect(() => {
        setSelectedLang(selectedLanguage);
    }, [selectedLanguage])

    const selectLanguage = (lang: SupportedLanguageType) => {
        dispatch(changeLanguage({ language: lang }));
        setSelectedLang(lang);
        setOpen(false);
    }

    return (
        <DropdownContainer>
            <LanguagesContainer onClick={handleOpen}>
                <LangIconStyle>
                    <LangIcon src={langIcon} />
                </LangIconStyle>
                <LangValueStyle>
                    {selectedLang}
                </LangValueStyle>
                {/* <LangArrowStyle>
                    <ArrowIcon src={arrowIcon} />
                </LangArrowStyle> */}
            </LanguagesContainer>
            <DropdownMenu open={open}>
                {languages.map((lang, index) => (
                    <DropdownItem key={index} onClick={() => selectLanguage(lang)}>
                        {lang}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </DropdownContainer>
    )
}

export default LanguagesController;
