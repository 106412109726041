import styled from "styled-components";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TextField } from "@mui/material";

interface boxContainerProps {
    boxSize: "big" | "small"
}

export const BoxContainer = styled.div<boxContainerProps>`
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: ${props => props.boxSize === "big" ? 90 : 80}%;
    height: ${props => props.boxSize === "big" ? 80 : 50}%;
    z-index: 10;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
    transition: all 0.5s ease;
`

export const BoxBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #c5a496;
    height: auto;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #222532;
`

export const ChangeStatusBtnStyle = styled.div`
    padding: 10px;
    background-color: #c5a496;
    cursor: pointer;
    font-family: 'Jost',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #222532;
    display: flex;
    justify-content: center;
`

export const LocalizationProviderStyle = styled(LocalizationProvider)``;

export const InputTimeStyle = styled(TimePicker)``;

export const InputCalendarStyle = styled(DateCalendar)`
    .Mui-selected {
        background-color: #222532 !important; 
        color: white !important; 
    }
`;

export const InputContainerStyle = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
});

export const InputTextStyle = styled(TextField)`
    width: 100%;
    color: black;
`

export const ChoiceBoxStyle = styled.div`
    display: flex;
    align-items: center;
`

export const LabelShiftStyle = styled.div`
    width: 130px;
`

export const AddFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 3px 10px;
    gap: 20px;
    /* border: 0.5px solid black */
`

export const BoxTitleStyle = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0 ;
`

export const SubmitBtnStyle = styled.div`
    text-align: center;
    padding: 5px 0;
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
    border: 2px solid #222532;

    &:hover {
        background-color: #222532;
        color: white;
    }
`

export const TableContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
    overflow-y: auto;
    cursor: grab;

    /* Style scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
        width: 8px;  /* Adjust width of scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;  /* Color of the track */
    }

    ::-webkit-scrollbar-thumb {
        background: #888;  /* Color of the scroll thumb */
        border-radius: 4px;  /* Radius of the scroll thumb */
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #555;  /* Color of the scroll thumb on hover */
    }

    /* Style scrollbar for Firefox */
    scrollbar-width: thin;  /* Adjust width of scrollbar */
    scrollbar-color: #888 #f1f1f1;  /* Color of the scrollbar */
`

export const RowStyle = styled.div`
    display: flex;
    padding: 7px 10px;
    justify-content: space-between;
    background-color: #222532;
    color: white;
`

export const RowDateStyle = styled.div`
    display: flex;
    align-items: center;
`

export const RowTimeStyle = styled.div`

`

export const RowDeleteButtonStyle = styled.img`
    width: 25px;
    cursor: pointer;
`

export const TRStyle = styled.tr`
    max-width: calc(100% - 100px);
`

export const ShiftTimeStyle = styled.span`
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%; /* Position the tooltip's left edge in the center */
    transform: translateX(-50%); /* Shift the tooltip back by half its width */
    opacity: 0;
    transition: opacity 0.3s;
`;

export const ShiftContainer = styled.div`
    display: flex;
    gap: 5px;
    padding: 5px 10px;
    background-color: #b0943e;
    border-radius: 5px;
    user-select: none;

    position: relative;
  
    &:hover ${ShiftTimeStyle} {
        visibility: visible;
        opacity: 1;
    }
`

export const ShiftTitleStyle = styled.div`

`

export const ShiftDeleteStyle = styled.div`
    cursor: pointer;
`

export const TDShiftsStyle = styled.td`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
`